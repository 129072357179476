import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button } from "../../theme/components";
import { useNavigate } from "react-router-dom";
import EventBus from "eventing-bus";
import {
  TrainingModuleBox,
  ContentBox,
  ModuleCard,
  Banner,
  CardsContainer,
  ModuleContent,
  ProgressBar,
  ProgressCountBox
} from "./trainingModule.styled";
import { useLocation } from "react-router-dom";
import { requestGetModulesList } from "../../redux/actions";
import VideoPlayer from "../../assets/icons/videoindicator.svg";
import { GLOBAL_TYPES } from "../../redux/types";
import TrainingBanner from "../../assets/images/trainingbanner.png";
import { errorToast } from "../../utility/toasts";

// the query string for you.
function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function TrainingModules(props) {
  let query = useQuery();
  const userData = props?.user?.userData?.nasscomUserDetails
  const [isLoading, setIsLoading] = useState(true)
  let navigate = useNavigate();
  const [modules, setModules] = useState(null)

  useEffect(() => {
    // the api required the userId and the slug (trainign Id ) condition helps to dont miss any query data
    if (query.get('trainingId') && userData?.nasscom_user_id) {
      getModulesListData(userData?.nasscom_user_id, query.get('trainingId'));
    } else {
      navigate(-1);
      setTimeout(() => {
        errorToast('please select any training to acess')
      }, 500);
    }
  }, [query, userData]) //on training id change api will render the data which training is selected

  useEffect(() => {
    //ToGet The modules Data 
    const requestModulesData = EventBus.on(
      GLOBAL_TYPES.GET_MODULES_LIST,
      () => {
        setIsLoading(true)
      }
    );
    const getModulesDataSuccess = EventBus.on(
      GLOBAL_TYPES.GET_MODULES_LIST_SUCCESS,
      (res) => {
        if (res) {
          console.log(res?.data?.items)
          setModules(res?.data?.items)
          setIsLoading(false)
        }
      }
    );
    const getModulesDataFailure = EventBus.on(
      GLOBAL_TYPES.GET_MODULES_LIST_FAILED,
      () => {
        setIsLoading(false)
        navigate(-1)
      }
    );

    return () => {
      requestModulesData();
      getModulesDataSuccess();
      getModulesDataFailure();
    }
  }, [userData]) //runs everytime on the user data chage 

  // redirecting user to the page where he can access the selected module
  const redirectUser = (uuid) => {
    navigate(`/module/${uuid}`)
  }

  // to get the module data which was selected
  const getModulesListData = (uid, trainingId) => {
    const reqData = {
      userId: uid,
      trainingId: trainingId
    }
    props.requestGetModulesList(reqData);
  }
  // on page loading will be resndered
  if (isLoading) return <div style={{ padding: '50px' }}>Loading ...</div>

  return (
    <TrainingModuleBox>
      <h3 className="title">Course Modules</h3>
      <CardsContainer>
        {modules?.map((module, index) => {
          return (
            <ModuleCard key={index}>
              <ContentBox>
                <Banner style={{ backgroundColor: `${index === 0 ? '#cfffc8' : modules[index - 1]?.isUserCompleted ? '#cfffc8' : '#d9d9d9'}` }}>
                  <img src={TrainingBanner} style={{ borderRadius: '6px  6px 0px 0px' }} width={'100%'} height={'100%'} />
                </Banner>
                <ModuleContent>
                  <p>{module?.title.substring(0, 40)}</p>
                  <ProgressCountBox>
                    <img src={VideoPlayer} alt="logo" />
                    <span>Completed Videos {module?.totalVideosCompleted} of {module?.totalVideos}</span>
                  </ProgressCountBox>
                  <ProgressBar style={{ backgroundColor: index !== 0 && !modules[index - 1]?.isUserCompleted && '#d9d9d9' }}>
                    <div className="liveProgress" style={{ width: (module?.totalVideosCompleted / module?.totalVideos) * 100 + "%", backgroundColor: index !== 0 && !modules[index - 1]?.isUserCompleted && '#d9d9d9' }}>
                    </div>
                  </ProgressBar>
                  <Button onClick={() => redirectUser(module.id)} small outline={true} style={{ width: '80%', marginTop: '0.5rem' }} disabled={index === 0 ? false : !modules[index - 1]?.isUserCompleted}  >
                    {/* <Button onClick={() => redirectUser(module.id)} small outline={true} style={{ width: '80%', marginTop: '0.5rem' }}  > */}
                    Continue Module
                  </Button>
                </ModuleContent>
              </ContentBox>
            </ModuleCard>
          )
        })}
      </CardsContainer>
    </TrainingModuleBox>
  );
}
function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, {
  requestGetModulesList
})(TrainingModules);