import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button } from "../../theme/components";
import { useNavigate } from "react-router-dom";
import EventBus from "eventing-bus";
import {
  DashboardBox,
  ContentBox,
  ModuleCard,
  Banner,
  CardsContainer,
  ModuleContent,
  ProgressBar,
  ProgressCountBox
} from "./dashboard.styled";
import { requestGetModulesList } from "../../redux/actions";
import VideoPlayer from "../../assets/icons/videoindicator.svg";
import { GLOBAL_TYPES } from "../../redux/types";
import TrainingBanner from "../../assets/images/trainingbanner.png";
// ********************************************************************
// ** This component is not ready so lotf of code was commented out   * 
// ** once the apis and everything is ready will be updated           *  
// ** for now its running on heard coded data only                    *
// ********************************************************************
function Dashboard(props) {
  // extracting the user data from the props
  const userData = props?.user?.userData?.nasscomUserDetails
  const [isLoading, setIsLoading] = useState(false)
  let navigate = useNavigate();
  const [modules, setModules] = useState([
    { id: 4, title: 'AWS Cloud Training' },
    { id: 5, title: 'Cloud Computing 101 - Azure' },
    { id: 6, title: 'AI on Cloud' }])

  // useEffect(() => {
  //   //ToGet The Coupons Data 
  //   const requestModulesData = EventBus.on(
  //     GLOBAL_TYPES.GET_MODULES_LIST,
  //     () => { setIsLoading(true) }
  //   );
  //   const getModulesDataSuccess = EventBus.on(
  //     GLOBAL_TYPES.GET_MODULES_LIST_SUCCESS,
  //     (res) => {
  //       if (res) {
  //         console.log(res?.data?.items)
  //         setModules(res?.data?.items)
  //         setIsLoading(false)
  //       }
  //     }
  //   );
  //   const getModulesDataFailure = EventBus.on(
  //     GLOBAL_TYPES.GET_MODULES_LIST_FAILED,
  //     () => {
  //       setIsLoading(false)
  //     }
  //   );
  //   console.log(userData)
  //   if (userData?.nasscom_user_id) {
  //     getModulesListData(userData?.nasscom_user_id);
  //   }

  //   return () => {
  //     requestModulesData();
  //     getModulesDataSuccess();
  //     getModulesDataFailure();
  //   }
  // }, [userData])

  const redirectUser = (id) => {
    navigate(`/training-modules?trainingId=${id}`)
  }

  // const getModulesListData = (uid) => {
  //   const reqData = {
  //     userId: uid,
  //     trainingId: 4
  //   }
  //   props.requestGetModulesList(reqData);
  // }

  if (isLoading) return <div style={{ padding: '50px' }}>Loading ...</div>

  return (
    <DashboardBox>
      <h3 className="title">Dashboard</h3>
      <CardsContainer>
        {modules?.map((module, index) => {
          return (
            <ModuleCard key={index}>
              <ContentBox>
                <Banner style={{ backgroundColor: `${index === 0 ? '#cfffc8' : modules[index - 1]?.isUserCompleted ? '#cfffc8' : '#d9d9d9'}` }}>
                  <img src={TrainingBanner} style={{ borderRadius: '6px  6px 0px 0px' }} width={'100%'} height={'100%'} />
                </Banner>
                <ModuleContent>
                  <p>{module?.title.substring(0, 40)}</p>
                  {/* <ProgressCountBox>
                    <img src={VideoPlayer} alt="logo" />
                    <span>Completed Videos {module?.totalVideosCompleted} of {module?.totalVideos}</span>
                  </ProgressCountBox> */}
                  {/* <ProgressBar style={{ backgroundColor: index !== 0 && !modules[index - 1]?.isUserCompleted && '#d9d9d9' }}>
                    <div className="liveProgress" style={{ width: (module?.totalVideosCompleted / module?.totalVideos) * 100 + "%", backgroundColor: index !== 0 && !modules[index - 1]?.isUserCompleted && '#d9d9d9' }}>
                    </div>
                  </ProgressBar> */}
                  <Button
                    onClick={() => redirectUser(module.id)}
                    small outline={true}
                    style={{ width: '80%', marginTop: '0.5rem' }} >
                    Start Training
                  </Button>
                </ModuleContent>
              </ContentBox>
            </ModuleCard>
          )
        })}
      </CardsContainer>
    </DashboardBox>
  );
}
function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, {
  // requestGetModulesList
})(Dashboard);