import React from "react";
import { connect } from "react-redux";
import cx from "classnames";
import {
  SidebarComponentBox,
  ListBox,
  SidebarHeader,
  SidebarContentBox,
  SidebarContentUpper,
  ListDataBox,
  TrainingTitle
} from "./sidebar.styled";
import { signOutUserSuccess } from "../../../redux/actions";
import DesktopLogoImg from "../../../assets/icons/desktopLogo.svg";
import { ReactComponent as CompletedIcon } from "../../../assets/icons/completedIcon.svg";
import { ReactComponent as NotCompletedIcon } from "../../../assets/icons/notcompletedIcon.svg";
function Sidebar({ moduleData, setCurrentVideo, videoTitle, currentVideo }) {
  return (
    <SidebarComponentBox>
      <SidebarHeader>
        <img src={DesktopLogoImg} alt="logo" />
      </SidebarHeader>
      <SidebarContentBox>
        <TrainingTitle>
          {videoTitle} <br />
          <span>Affcor Foundation & SRDS</span>
        </TrainingTitle>
        <SidebarContentUpper>
          {moduleData?.map((data, index) => {
            return (
              <ListBox key={data?.id}>
                <div
                  className={cx({
                    sideBaractive: data.id === currentVideo,
                  })}
                  onClick={() => {
                    if (moduleData[index - 1]?.isVideoCompleted) {
                      setCurrentVideo(data)
                    } else if (index === 0) {
                      setCurrentVideo(data)
                    }
                  }}
                >
                  <ListDataBox>
                    <div className="listDataLeftSide">
                      <div>
                        {data?.isVideoCompleted ?
                          <CompletedIcon style={{ marginRight: '0.8rem' }} /> :
                          <NotCompletedIcon style={{ marginRight: '0.8rem' }} />}
                        Video  {index + 1}
                      </div>
                      <span>{data?.videoLength}min</span>
                    </div>
                  </ListDataBox>
                </div>
              </ListBox>
            );
          })}
        </SidebarContentUpper>
      </SidebarContentBox>
    </SidebarComponentBox>
  );
}

function mapStateToProps(state) {
  return {
    theme: state?.theme,
    user: state?.user,
  };
}

export default connect(mapStateToProps, { signOutUserSuccess })(Sidebar);
