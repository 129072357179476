import React, { useEffect, useState } from 'react'
import { AttendanceContentBox } from './attendance.styled'
import { Table, Radio, Button, Modal } from '../../../theme/components';
import { AttendanceVideosBox } from '../moduleContent.styled';
import EventBus from "eventing-bus";
import { connect } from "react-redux";
import { ATTENDANCE_TYPES } from '../../../redux/types';
import { requestGetAttendance, requestGetUsersAttendance, requestMarkAttendance } from '../../../redux/actions';
function Attendance(props) {
    const { data } = props
    const [usersAttendanceData, setUsersAttendanceData] = useState([]);
    const [usersData, setUsersData] = useState([]);
    const [isConfirmationWindowOpen, setIsConfirmationWindowOpen] = useState(false);
    const [markAttendance, setMarkAttendance] = useState({ videoId: data?.id, data: [] })
    useEffect(() => {
        if (usersAttendanceData && usersData.length > 0) {
            const copyUserData = usersData?.map((userIt, userIndex) => {
                const user = { ...userIt }
                user.isPresent = ''
                usersAttendanceData?.map(attendance => {
                    if (attendance.id === userIt.id) {
                        user.isPresent = attendance.progress[0].isPresent
                    }
                })
                return user
            })
            setUsersData(copyUserData)
        }
    }, [usersAttendanceData])

    useEffect(() => {
        if (markAttendance.data.length > 0) {
            props.requestMarkAttendance(markAttendance)
        }
    }, [markAttendance])

    useEffect(() => {
        //ToGet The Coupons Data 
        const requestGetAttendance = EventBus.on(
            ATTENDANCE_TYPES.GET_ATTENDANCE,
            () => { }
        );
        const requestGetAttendanceSuccess = EventBus.on(
            ATTENDANCE_TYPES.GET_ATTENDANCE_SUCCESS,
            (res) => {
                if (res) {
                    setUsersAttendanceData(res.data)
                }
            }
        );
        const requestGetAttendanceFailure = EventBus.on(
            ATTENDANCE_TYPES.GET_ATTENDANCE_FAILED,
            () => {
            }
        );

        //videos progress
        const requestGetUsersAttendance = EventBus.on(
            ATTENDANCE_TYPES.GET_ATTENDANCE_USERS_LIST,
            () => { }
        );
        const requestGetUsersAttendanceSuccess = EventBus.on(
            ATTENDANCE_TYPES.GET_ATTENDANCE_USERS_LIST_SUCCESS,
            (res) => {
                if (res) {
                    setUsersData(res?.data)
                    getUsersListById();
                }
            }
        );
        const requestGetUsersAttendanceFailure = EventBus.on(
            ATTENDANCE_TYPES.GET_ATTENDANCE_USERS_LIST_FAILED,
            () => {
            }
        );

        //videos progress
        const requestMarkAttendance = EventBus.on(
            ATTENDANCE_TYPES.MARK_ATTENDANCE,
            () => { }
        );
        const requestMarkAttendanceSuccess = EventBus.on(
            ATTENDANCE_TYPES.MARK_ATTENDANCE_SUCCESS,
            (res) => {
                getUsersListById();
            }
        );
        const requestMarkAttendanceFailure = EventBus.on(
            ATTENDANCE_TYPES.MARK_ATTENDANCE_FAILED,
            () => {
            }
        );

        getUsersList();

        return () => {
            requestGetAttendance();
            requestGetAttendanceSuccess();
            requestGetAttendanceFailure();

            requestGetUsersAttendance();
            requestGetUsersAttendanceSuccess();
            requestGetUsersAttendanceFailure();

            requestMarkAttendance();
            requestMarkAttendanceSuccess();
            requestMarkAttendanceFailure();
        }
    }, [])

    const getUsersList = () => {
        const reqData = { trainingId: 4 }
        props.requestGetUsersAttendance(reqData)
    }

    const getUsersListById = () => {
        const reqData = { videoId: data?.id }
        props.requestGetAttendance(reqData);
    }

    let sno = 1;
    const columns = [
        {
            key: "Sno.",
            title: "Sno.",
            render: (data, type, row) => {
                return sno++
            }
        },
        {
            key: "Name",
            title: "Name",
            render: (data, type, row) => {
                return type?.nasscomUserDetails?.firstName + type?.nasscomUserDetails?.lastName
            }
        },
        {
            key: "Email",
            title: "Email",
            render: (data, type, row) => {
                return type?.emailId
            }
        },
        {
            key: "Present",
            title: "Present",
            render: (data, type, row) => {
                return <Radio checked={type?.isPresent} onClick={() => {
                    type.isPresent = true
                    const reqObj = {
                        isPresent: true,
                        userId: type.id
                    }
                    setMarkAttendance({ ...markAttendance, data: [reqObj] })
                }} />
            }
        },
        {
            key: "Absent",
            title: "Absent",
            render: (data, type, row) => {
                return <Radio checked={type?.isPresent === false} onClick={() => {
                    type.isPresent = false
                    const reqObj = {
                        isPresent: false,
                        userId: type.id
                    }
                    setMarkAttendance({ ...markAttendance, data: [reqObj] })
                }} />
            }
        },
    ]

    return (
        <AttendanceContentBox>
            <Modal show={isConfirmationWindowOpen} onClose={() => setIsConfirmationWindowOpen(false)} maxWidth='50' >
                <h3>Do you want to mark all as present ?</h3>
                <AttendanceVideosBox>
                    <div className="footer">
                        <Button small outline={true} style={{ marginLeft: '2rem' }}  onClick={() => {
                            console.log('continue')
                        }} >
                            Confirm
                        </Button>
                    </div>
                </AttendanceVideosBox>
            </Modal>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                    <Button outline small onClick={() => props.setIsAttendanceEnabled(false)} >Back</Button>
                    <h3>Attendance</h3>
                </div>
                {/* <Button outline small onClick={() => setIsConfirmationWindowOpen(true)}>Mark All As Present</Button> */}
            </div>
            <Table
                columns={columns}
                data={usersData}
            />
        </AttendanceContentBox>
    )
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps, {
    requestGetAttendance,
    requestGetUsersAttendance,
    requestMarkAttendance
})(Attendance);