import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import cx from "classnames";
import {
  SidebarComponentBox,
  ListBox,
  SidebarHeader,
  SidebarContentBox,
  SidebarContentUpper,
  SidebarContentBottom,
  SidebarProfileContentBox,
  ListDataBox,
  SidebarContentBottomInnerBox,
  HoverProfileCard,
  ProfileOptionCard,
  TrainingTitle,
} from "./sidebar.styled";
import { signOutUserSuccess } from "../../../../redux/actions";
import { Constant } from "../../../../helpers";
import DesktopLogoImg from "../../../../assets/icons/desktopLogo.svg";
import DownArrowImg from "../../../../assets/icons/arrow-down.svg";
import { ReactComponent as DefaultProfileImage } from "../../../../assets/icons/profile.svg";
import { ReactComponent as UserImage } from "../../../../assets/icons/user.svg";
import { ReactComponent as QuestionImage } from "../../../../assets/icons/question.svg";
import { ReactComponent as LogoutImage } from "../../../../assets/icons/logout.svg";
import { LStorage } from "../../../../helpers/localstorage.helper";

function Sidebar(props) {
  const location = useLocation();
  const userData = LStorage.getUserData();
  let defaultOpenChildListIndex = "";
  Constant.sidebar().some((data, index) => {
    if (data?.childrens) {
      return data?.childrens.some((childData) => {
        const isChildRouteFounded =
          childData?.route && location.pathname.startsWith(childData?.route);
        if (isChildRouteFounded) defaultOpenChildListIndex = index;
        return isChildRouteFounded;
      });
    }
    return false;
  });

  const [openChildListIndex, setOpenChildListIndex] = useState(
    defaultOpenChildListIndex
  );

  const ListData = (props) => {
    const { data, isActiveRoute, onClick, isChildOpen } = props;

    return (
      <div>
        {data?.route ? (
          <div
            className={cx({
              sideBaractive: isActiveRoute,
            })}
          >
            <Link to={data?.route}>
              <ListDataBox>
                <div className="listDataLeftSide">
                  {data.icon && <data.icon className="sidebarListIcon" />}
                  {data?.name}
                </div>
              </ListDataBox>
            </Link>
          </div>
        ) : (
          <div
            className={cx({
              sideBaractive: isActiveRoute,
            })}
          >
            <ListDataBox onClick={onClick}>
              <div className="listDataLeftSide">
                <data.icon className="sidebarListIcon" />
                {data?.name}
              </div>
              <img
                src={DownArrowImg}
                alt="down-icon"
                className={cx({ listDataLeftSideUpdownArrow: isChildOpen })}
              />
            </ListDataBox>
          </div>
        )}
      </div>
    );
  };

  return (
    <SidebarComponentBox>
      <SidebarHeader>
        <img src={DesktopLogoImg} alt="logo" />
        {/* <div className="sidebarLeftIcon">
          <img src={LeftImg} alt="lefticon" />
        </div> */}
      </SidebarHeader>
      <SidebarContentBox>
        <SidebarContentUpper>
          {/* <TrainingTitle>
            Basics of Cloud Computing Training <br />
            <span>Affcor Foundation & SRDS</span>
          </TrainingTitle> */}
          {Constant.sidebar().map((data, index) => {
            let isActiveRoute =
              data?.route && location.pathname.startsWith(data?.route);
            if (location.pathname === "/" && data?.route === "/dashboard") {
              isActiveRoute = true;
            }

            return (
              <ListBox key={data?.key}>
                <ListData
                  data={data}
                  isActiveRoute={isActiveRoute}
                  isChildOpen={openChildListIndex === index}
                  openChildListIndex={openChildListIndex}
                  onClick={() =>
                    setOpenChildListIndex(
                      openChildListIndex === index ? "" : index
                    )
                  }
                />
                {data?.childrens &&
                  data?.childrens.length > 0 &&
                  openChildListIndex === index &&
                  data?.childrens.map((childData) => {
                    const isActiveChildRoute =
                      childData?.route &&
                      location.pathname.startsWith(childData?.route);
                    return (
                      <ListBox key={childData?.key} isChildren={true}>
                        <ListData
                          data={childData}
                          isActiveRoute={isActiveChildRoute}
                        />
                      </ListBox>
                    );
                  })}
              </ListBox>
            );
          })}
        </SidebarContentUpper>
        <SidebarContentBottom>
          <SidebarContentBottomInnerBox>
            <SidebarProfileContentBox>
              <div className="sidebarProfileImg">
                <DefaultProfileImage />
              </div>
              <div>
                <div className="sidebarProfileTitle">
                  {userData?.role_id === 2 ? "Trainer" : "Student"}
                  {/* {nasscomUserDetails?.first_name}{" "}
                  {nasscomUserDetails?.last_name} */}
                </div>
                <div className="sidebarProfileUser">
                  {/* Super-Admin */}
                  {/* {nasscomUserDetails?.occupation} */}
                </div>
              </div>
              <HoverProfileCard className="hoverProfile">
                {/* <ProfileOptionCard>
                  <UserImage className="profileOptionIcon" /> <p>Profile</p>
                </ProfileOptionCard>
                <ProfileOptionCard onClick={() => window.open('https://qwikskills.com/faq', '_blank', 'noreferrer')}>
                  <QuestionImage className="profileOptionIcon" /> <span>Support</span>
                </ProfileOptionCard> */}
                <ProfileOptionCard onClick={props.signOutUserSuccess}>
                  <LogoutImage className="profileOptionIcon" />{" "}
                  <span>Logout</span>
                </ProfileOptionCard>
              </HoverProfileCard>
            </SidebarProfileContentBox>
          </SidebarContentBottomInnerBox>
        </SidebarContentBottom>
      </SidebarContentBox>
    </SidebarComponentBox>
  );
}

function mapStateToProps(state) {
  return {
    theme: state?.theme,
    user: state?.user,
  };
}

export default connect(mapStateToProps, { signOutUserSuccess })(Sidebar);
