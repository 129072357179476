import React, { useEffect } from "react";
import { connect } from "react-redux";
import { signOutUserSuccess } from "../../../redux/actions";
import { useParams, useLocation } from "react-router-dom";
import Header from "./header";
import Sidebar from "./sidebar";
import {
  MainComponentBox,
  ContentBox,
  ContentChildrenBox,
} from "./main.styled";

function MainLayout(props) {
  const location = useLocation();
  // const { slug } = useParams();
  useEffect(() => {
    // we get undefiened if user navigated to home route or login page so condition applied to avoid errors
    if (location) {
      console.log(location.pathname.startsWith('/module/'))
    }
  }, [location])
  return (
    <MainComponentBox>
      {/* this condition helps to replace the sidebar if user eneteres into the video section */}
      {!location.pathname.startsWith('/module/') && <Sidebar />}
      <ContentBox>
        <Header />
        {/* <Button onClick={handleLogout}>Signout</Button> */}
        <ContentChildrenBox>{props?.children}</ContentChildrenBox>
      </ContentBox>
    </MainComponentBox>
  );
}

function mapStateToProps(state) {
  return {
    user: state?.user,
  };
}

export default connect(mapStateToProps, { signOutUserSuccess })(MainLayout);
