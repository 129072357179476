import { all } from "redux-saga/effects";
import { watchUserRequests } from "./user.saga";
import { watchAttendanceRequests } from "./attendance.saga";
import { watchGlobalRequests } from "./global.saga";
import { watchReportRequests } from "./report.saga";
export default function* rootSaga() {
  yield all([
    watchUserRequests(),
    watchAttendanceRequests(),
    watchGlobalRequests(),
    watchReportRequests(),
  ]);
}
