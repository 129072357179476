import { ReactComponent as DashboardImg } from "../assets/icons/dashboard.svg";

class Constant {
  static sidebar = () => {
    const sidebarData = [
      {
        icon: '',
        name: "Dashboard",
        route: "/dashboard",
        key: "dashboard",
      },
      {
        icon: '',
        name: "Modules",
        route: "/training-modules",
        key: "training-modules",
      },
      {
        icon: '',
        name: "Certificate",
        route: "/certificate",
        key: "certificate",
      },
      {
        icon: '',
        name: "Reports",
        route: "/reports",
        key: "reports",
      },
      {
        icon: '',
        name: "Leaderboard",
        route: "/leaderboard",
        key: "leaderboard",
      },
    ];
    return sidebarData;
  };

  static modules = () => {
    const MODULE_NAMES = {
      DASHBOARD: "DASHBOARD",
      CLOUDLABS: "CLOUDLABS",
      CERTIFICATIONS: "CERTIFICATIONS",
      TRAININGS: "TRAININGS",
      PAYMENTS: "PAYMENTS",
      PROFILE: "PROFILE",
      MYPRACTICE: "MYPRACTICE",
    };
    return MODULE_NAMES;
  };

  static urls = () => {
    return {
      image: "https://qwikappstorage.blob.core.windows.net/uploads",
    };
  };

}

export { Constant };
