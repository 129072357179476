import { takeEvery, call, put } from "redux-saga/effects";
import {
  getModulesListFailure,
  getModulesListSuccess,
  requestGetModuleByIdFailure,
  requestGetModuleByIdSuccess,
  requestGetVideosProgressFailure,
  requestGetVideosProgressSuccess,
  requestSubscribeVideosFailure,
  requestSubscribeVideosSuccess,
  requestMarkVideoAsCompletedFailure,
  requestMarkVideoAsCompletedSuccess,
  requestGetLeaderBoardUsersFailure,
  requestGetLeaderBoardUsersSuccess
} from "../actions";
import { GLOBAL_TYPES } from "../types";
import { getModulesList, getModulesById, getVideoProgress, subscribeToVideo, markVideoAsCompleted , getLeaderBoardUsers} from "../apis";

export function* watchGlobalRequests() {
  yield takeEvery(GLOBAL_TYPES.GET_MODULES_LIST, requestGetModulesList);
  yield takeEvery(GLOBAL_TYPES.GET_MODULES_BY_ID, requestGetModuleById);
  yield takeEvery(GLOBAL_TYPES.GET_VIDEOS_PROGRESS, requestGetVideosProgressById);
  yield takeEvery(GLOBAL_TYPES.REQUEST_SUBSCRIBE_VIDEO, requestSubscribeVideos);
  yield takeEvery(GLOBAL_TYPES.MARK_VIDEO_AS_COMPLETED, requestMarkVideoAsCompleted);
  yield takeEvery(GLOBAL_TYPES.GET_LEADERBOARD_USERS, requestGetLeaderBoardUsers);
}

function* requestGetModulesList(action) {
  try {
    const data = yield call(getModulesList, action.payload);
    yield put(getModulesListSuccess(data));
  } catch (e) {
    yield put(getModulesListFailure(e));
  }
}

function* requestGetModuleById(action) {
  try {
    const data = yield call(getModulesById, action.payload);
    yield put(requestGetModuleByIdSuccess(data));
  } catch (e) {
    yield put(requestGetModuleByIdFailure(e));
  }
}

function* requestGetVideosProgressById(action) {
  try {
    const data = yield call(getVideoProgress, action.payload);
    yield put(requestGetVideosProgressSuccess(data));
  } catch (e) {
    yield put(requestGetVideosProgressFailure(e));
  }
}

function* requestSubscribeVideos(action) {
  try {
    const data = yield call(subscribeToVideo, action.payload);
    yield put(requestSubscribeVideosSuccess(data));
  } catch (e) {
    yield put(requestSubscribeVideosFailure(e));
  }
}

function* requestMarkVideoAsCompleted(action) {
  try {
    const data = yield call(markVideoAsCompleted, action.payload);
    yield put(requestMarkVideoAsCompletedSuccess(data));
  } catch (e) {
    yield put(requestMarkVideoAsCompletedFailure(e));
  }
}

function* requestGetLeaderBoardUsers(action) {
  try {
    const data = yield call(getLeaderBoardUsers, action.payload);
    yield put(requestGetLeaderBoardUsersSuccess(data));
  } catch (e) {
    yield put(requestGetLeaderBoardUsersFailure(e));
  }
}