import styled from "styled-components";

export const TrainingModuleBox = styled.div`
.title{
  font-weight: 600;
}
  padding: 20px 20px;
  p{
    font-size: 1rem;
  }
  h3{
    font-size: 1.3rem;
    font-weight: 400;
  }
  h4{
    font-size: 1.3rem;
    font-weight: 400;
  }
`;

export const CardsContainer = styled.div`
  padding: 10px ;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
`;

export const ModuleCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const ContentBox = styled.div`
border-radius: 8px;
border: 1px solid black;
max-width: 332px;
`;

export const Banner = styled.div`
border-radius:8px;
width: 330px;
height:180px;
`;

export const ModuleContent = styled.div`
p{
  font-weight: 600;
  font-size: 13px;
}
display: flex;
flex-direction: column;
padding:10px 20px  20px 20px;
text-align: center;
align-items: center;
gap: 10px;
`;

export const ProgressBar = styled.div`
width: 100%;
height: 10px;
border-radius: 8px;
position: relative;
background-color: #cfffc8;
.liveProgress{
  position: absolute;
  height: 100%;
  background-color: #68cc58;
  border-radius: 5px;
}
`;

export const ProgressCountBox = styled.div`
display: flex;
width: 100%;
flex-direction: flex-start;
gap: 0.5rem;
span{
  font-weight: 500;
  font-size: 0.88rem;
}
`;