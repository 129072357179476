import styled from "styled-components";
import { COLORS } from "../../../../theme/colors";

export const HeaderBox = styled.div`
  min-height: 64px;
  background: ${COLORS.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 15px;
  border-bottom: 2px solid ${COLORS.grey03};
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 10;
  width:100%;
  h5{
    font-size: 0.9rem; 
  }
`;

export const LeftHeaderBox = styled.div`
  display: flex;
  align-items: center;
  img {
    margin-right: 8px;
  }
`;

export const RightHeaderBox = styled.div`
  display: flex;
  position: relative;
  .notification-img{
    &::after{
      content: "";
      background:${COLORS.blue};
      border-radius:100%;
      padding: 5px;
      position: absolute;
      left: 1.7rem;
    }
  }
`;