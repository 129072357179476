import React from "react";
import { connect } from "react-redux";
import {
  HeaderBox,
  LeftHeaderBox,
  RightHeaderBox,
} from "./header.styled";
import DesktopLogoImg from "../../../../assets/images/affcor.png";
import { updateThemeModeSuccess, requestGetNotifications, requestUpdateNotifications } from "../../../../redux/actions";

function Header(props) {

  return (
    <HeaderBox>
      <LeftHeaderBox>
        <img src={DesktopLogoImg} alt="logo" width='60px' />
      </LeftHeaderBox>
      <RightHeaderBox>
      </RightHeaderBox>
    </HeaderBox>
  );
}

function mapStateToProps(state) {
  return {
    theme: state?.theme,
    user: state?.user,
  };
}

export default connect(mapStateToProps, {
  updateThemeModeSuccess,
  requestGetNotifications,
  requestUpdateNotifications
})(Header);
