import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import EventBus from "eventing-bus";
import { REPORT_TYPES } from "../../redux/types";
import { PracticeTestBox, TableDataBox } from "./reports.styled";
import { Table } from "../../theme/components";
import {
  requestGetReportList,
  requestGetReportDetail,
} from "../../redux/actions";
import { Modal, Loader } from "../../theme/components";
import { isAnswerCorrect } from "../../utility/utils";
import { staticUserData } from "./userData";
import { affcorUserData } from "./affcorUserData";
import { staticReport } from "./staticReport";
function Reports(props) {
  const userData = props?.user?.userData?.role_id;
  const [reportId, setReportId] = useState();
  const [isShowStudenData, setIsShowStudenData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showMore, setShowMore] = useState("");
  const [data, setData] = useState([]);
  const [reportsData, setReportsData] = useState({});
  const [affcorUserDataList, setAffcorUserDataList] = useState({});
  let sno = 1;

  useEffect(() => {
    const requestReportList = EventBus.on(REPORT_TYPES.GET_REPORT_LIST, () =>
      setIsLoading(true)
    );
    const reportListSuccess = EventBus.on(
      REPORT_TYPES.GET_REPORT_LIST_SUCCESS,
      (res) => {
        setIsLoading(false);
        // setReportsData(res);
      }
    );
    const reportListFailure = EventBus.on(
      REPORT_TYPES.GET_REPORT_LIST_FAILED,
      () => {
        setIsLoading(false);
      }
    );
    const requestReportDetail = EventBus.on(
      REPORT_TYPES.GET_REPORT_DETAIL,
      () => setIsLoading(true)
    );
    const reportDetailSuccess = EventBus.on(
      REPORT_TYPES.GET_REPORT_DETAIL_SUCCESS,
      (res) => {
        setIsLoading(false);
        setData(res);
        console.log("res", res);
      }
    );
    const reportDetailFailure = EventBus.on(
      REPORT_TYPES.GET_REPORT_DETAIL_FAILED,
      () => {
        setIsLoading(false);
      }
    );

    return () => {
      requestReportList();
      reportListSuccess();
      reportListFailure();

      requestReportDetail();
      reportDetailSuccess();
      reportDetailFailure();
    };
  }, []);

  useEffect(() => {
    const params = {
      training_id: 30,
      quiz_id: 250,
      size: 10,
      page: currentPage,
    };
    // requestGetReportList(params);
  }, [currentPage]);

  useEffect(() => {
    if (userData === 2) {
      setReportsData(staticUserData);
    } else {
      setReportsData(affcorUserData);
    }
    setAffcorUserDataList(affcorUserData);
    console.log(userData);
  }, [userData]);

  const columns = [
    {
      key: "No",
      title: "No.",
      render: (data, type, row) => {
        return sno++;
      },
    },
    isShowStudenData && {
      key: "name",
      title: "Name",
      render: (data, type, row) => {
        return type.Name;
      },
    },
    userData !== 2 && {
      key: "practiceTest",
      title: "Practice Test Name",
      render: (data, type, row) => {
        return type.practiceTest;
      },
    },
    {
      key: "Training Name",
      title: "Training Name",
      render: (data, type, row) => {
        return type.trainingName;
      },
    },
    {
      key: "Score",
      title: "Score",
      render: (data, type, row) => {
        return type.score;
      },
    },
    {
      key: "Percentage",
      title: "Percentage",
      render: (data, type, row) => {
        return type.percentage;
      },
    },
    {
      key: " View",
      title: "View",
      render: (data, type, row) => {
        return (
          <div
            onClick={() => {
              setData(staticReport);
              if (userData === 2 && !isShowStudenData) {
                setIsShowStudenData(true);
                // setData(staticReport);
              } else {
                setIsShowStudenData(false);
                setReportId(type["quizId"]);
                // requestGetReportDetail({
                //   userId: type["userId"],
                //   quizId: type["quizId"],
                // });
              }
            }}
            style={{ cursor: "pointer", color: "blue", fontSize: "1.1rem" }}
          >
            View
          </div>
        );
      },
    },
  ];

  return (
    <PracticeTestBox>
      <Loader isLoading={isLoading} />
      <h3 className="title">Report</h3>
      <Modal
        title={<h3>{data?.quizTitle}</h3>}
        show={Boolean(reportId)}
        onClose={() => {
          setReportId("");
          if (userData === 2) setIsShowStudenData(true);
        }}
      >
        <h4 className="mt-2">Result Summary</h4>
        <table className="table table-striped">
          <thead className="thead-light">
            <tr>
              <th scope="col">Practice Test Name</th>
              <th scope="col">Type</th>
              <th scope="col">% Score</th>
              <th scope="col">Result</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{data?.quizTitle}</td>
              <td>{data?.quizType ? "Complete" : "Free"}</td>
              <td>{data?.quizScore}%</td>
              <td>
                {data?.quizScore >= data?.passingScore ? "Passed" : "Failed"}
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <h4 className="mt-2">Your Detailed Assessment Report</h4>
        <table className="table table-striped">
          <thead className="thead-light">
            <tr>
              <th>S. NO.</th>
              <th>Category</th>
              <th>Total Q.</th>
              <th>Correct</th>
              <th>Incorrect</th>
              <th>Not Attempted</th>

              <th>Time Taken</th>
            </tr>
          </thead>
          <tbody>
            {/* <tr> */}
            {data?.questionGroup &&
              data.questionGroup.map((value, i) => {
                return (
                  <tr key={`category-${i}`}>
                    <td>{i + 1}</td>
                    <td>{value?.category}</td>
                    <td>{value?.totalQuestions}</td>
                    <td>{value?.rightAnswer}</td>
                    <td>{value?.wrongAnswer}</td>
                    <td>{value?.notAttempted}</td>
                    <td>{value?.timeTaken}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <br />

        <h4 className="mt-1">Check Detailed Answers</h4>
        {data.quizquestions
          ? data.quizquestions.map((value, i) => {
              const questionAnswer = data.quizuserquestions
                ? data.quizuserquestions.filter(
                    (val) => val.questionId === value?.questionId
                  )[0]
                : null;

              let isCorrectAnswer = data.quizuserquestions
                ? data.quizuserquestions.find(
                    (data) => data?.questionId === value?.questionId
                  )?.isCorrect
                : null;
              let isCorrect = questionAnswer
                ? isAnswerCorrect(
                    value.question?.answers,
                    questionAnswer.userAnswerId
                  )
                : null;

              let isDragAnswer = false;

              if (
                value?.question?.questionType?.questionTypeName ===
                  "drag_and_drop_grid" ||
                value?.question?.questionType?.questionTypeName ===
                  "dropdown" ||
                value?.question?.questionType?.questionTypeName ===
                  "drag_and_drop"
              ) {
                isDragAnswer = true;
              }

              return (
                <div
                  key={`question-${i}`}
                  className="questions-block"
                  style={{
                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                    paddingBottom: "2px",
                  }}
                >
                  <div
                    className={`title-block mt-2 ${
                      isCorrectAnswer ? `correct` : `incorrect`
                    }`}
                  >
                    {`Question ${i + 1}`}
                    <span className={isCorrectAnswer ? `correct` : `incorrect`}>
                      {isCorrectAnswer === true
                        ? `You were Correct`
                        : isCorrectAnswer === false
                        ? `You were Incorrect`
                        : "Not Attempted"}
                    </span>
                  </div>
                  <div className="box-content">
                    <div
                      className="mt-2"
                      style={{
                        display: "flex",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                      }}
                    >
                      <p style={{ marginRight: "6px" }}>Q.{i + 1} </p>{" "}
                      <p
                        dangerouslySetInnerHTML={{
                          __html: value?.question?.description,
                        }}
                      ></p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        paddingRight: "20px",
                      }}
                    >
                      <p
                        onClick={() =>
                          setShowMore(showMore == i + 1 ? "" : i + 1)
                        }
                        style={{
                          cursor: "pointer",
                          color: "#ffff",
                          padding: "5px 8px",
                          fontSize: "1rem",
                          backgroundColor: "#5B43EA",
                          borderRadius: "5px",
                        }}
                      >
                        {showMore == i + 1 ? "Show Less" : `Show More`}
                      </p>
                    </div>
                    {showMore == i + 1
                      ? value.question &&
                        value.question.answers &&
                        value.question.answers.length
                        ? value.question.answers.map((option, i) => {
                            let classes = isAnswerCorrect(
                              option,
                              questionAnswer?.userAnswerId
                            );
                            const ques =
                              (value.question?.dropDownQuestions &&
                                JSON.parse(
                                  value.question?.dropDownQuestions
                                )) ||
                              [];

                            return (
                              <div
                                key={`option-${i}`}
                                className=" pl-2 show_ans mt-2 pr-1"
                              >
                                <div
                                  className={
                                    classes === 1 && !isDragAnswer
                                      ? `p-1 bg-light-success `
                                      : classes === 0 && !isDragAnswer
                                      ? `p-1 bg-light-danger`
                                      : `p-1`
                                  }
                                >
                                  <strong>{i + 1}</strong>.{" "}
                                  {value?.question?.questionType
                                    ?.questionTypeName === "dropdown"
                                    ? ques[i]?.title
                                    : option.answer}
                                </div>
                              </div>
                            );
                          })
                        : "No option found"
                      : " "}

                    {showMore == i + 1 &&
                      (value?.question?.questionType?.questionTypeName ===
                        "drag_and_drop" ||
                        value?.question?.questionType?.questionTypeName ===
                          "drag_and_drop_grid" ||
                        value?.question?.questionType?.questionTypeName ===
                          "dropdown") &&
                      value.question &&
                      value.question.answers &&
                      value.question.answers.length > 0 && (
                        <div className="pl-2 pt-3">Correct answers</div>
                      )}

                    {showMore == i + 1 &&
                    (value?.question?.questionType?.questionTypeName ===
                      "drag_and_drop" ||
                      value?.question?.questionType?.questionTypeName ===
                        "drag_and_drop_grid" ||
                      value?.question?.questionType?.questionTypeName ===
                        "dropdown" ||
                      value?.question?.questionType?.questionTypeName ===
                        "yes_no")
                      ? value.question &&
                        value.question.answers &&
                        value.question.answers.length
                        ? value.question.answers
                            .filter((data) => data?.answerOrder >= 0)
                            .sort((a, b) => a.answerOrder - b.answerOrder)
                            .map((option, i) => {
                              let classes = isAnswerCorrect(
                                option,
                                questionAnswer?.userAnswerId
                              );
                              if (
                                value?.question?.questionType
                                  ?.questionTypeName === "drag_and_drop" ||
                                value?.question?.questionType
                                  ?.questionTypeName === "drag_and_drop_grid" ||
                                value?.question?.questionType
                                  ?.questionTypeName === "dropdown" ||
                                value?.question?.questionType
                                  ?.questionTypeName === "yes_no"
                              ) {
                                classes = isCorrectAnswer === false ? 0 : 1;
                              }
                              return (
                                <div
                                  key={`option-${i}`}
                                  className=" pl-2 show_ans mt-2 pr-1"
                                >
                                  <div
                                    className={
                                      classes === 1
                                        ? `p-1 bg-light-primary `
                                        : classes === 0
                                        ? `p-1 p-1 bg-light-primary`
                                        : `p-1`
                                    }
                                  >
                                    <strong>{i + 1}</strong>.{" "}
                                    {value?.question?.questionType
                                      ?.questionTypeName === "dropdown" ||
                                    value?.question?.questionType
                                      ?.questionTypeName === "yes_no"
                                      ? option?.correctOption
                                      : option.answer}
                                  </div>
                                </div>
                              );
                            })
                        : "No option found"
                      : " "}
                  </div>
                  {showMore == i + 1 && value?.question?.explanation && (
                    <p
                      className="mt-2 bg-light m-1 p-1"
                      dangerouslySetInnerHTML={{
                        __html: value?.question?.explanation.replace(
                          /(?:\r\n|\r|\n)/g,
                          "<br />"
                        ),
                      }}
                    ></p>
                  )}
                </div>
              );
            })
          : null}
      </Modal>
      <Modal
        title={<h3>Student Data</h3>}
        show={Boolean(isShowStudenData)}
        onClose={() => {
          setIsShowStudenData(false);
        }}
      >
        <TableDataBox>
          <Table
            columns={columns}
            data={affcorUserDataList?.data}
            totalItems={affcorUserDataList?.totalItems}
            totalPages={affcorUserDataList?.totalPages}
            pageNo={currentPage}
            onPageChange={(currentPage) => {
              setCurrentPage(currentPage);
            }}
          />
        </TableDataBox>
      </Modal>
      <TableDataBox>
        <Table
          columns={columns}
          data={reportsData?.data}
          totalItems={reportsData?.totalItems}
          totalPages={reportsData?.totalPages}
          pageNo={currentPage}
          onPageChange={(currentPage) => {
            setCurrentPage(currentPage);
          }}
        />
      </TableDataBox>
    </PracticeTestBox>
  );
}
function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, {
  requestGetReportList,
  requestGetReportDetail,
})(Reports);
