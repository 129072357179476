import styled from "styled-components";

export const PracticeTestBox = styled.div`
  padding: 20px 20px;
  .title{
  font-weight: 600;
}
h3{
    font-size: 1.3rem;
    font-weight: 400;
  }
`;

export const TableDataBox = styled.div`
  padding: 20px 0px ;
`;