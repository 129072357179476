export const staticReport = {
    questionGroup: [
        {
            category: "AWS Services",
            totalQuestions: 1,
            rightAnswer: 0,
            wrongAnswer: 1,
            notAttempted: 13,
            timeTaken: "00:00:00"
        },
        {
            category: "Cloud Computing Concepts and Architectures",
            totalQuestions: 0,
            rightAnswer: 0,
            wrongAnswer: 0,
            notAttempted: 1,
            timeTaken: "00:00:00"
        }
    ],
    quizId: 227,
    quizTitle: "Week 6, Assessment 1",
    quizType: true,
    quizquestions: [
        {
            questionId: 25902,
            question: {
                id: 25902,
                uuid: "cef9967a-4999-42a1-8739-caa8af8d5b9f",
                title: "AWT56",
                description: "<p>If a customer needs to audit the change management of AWS resources, which of the following AWS services should the customer use?</p>",
                dropDownQuestions: "[{\"id\":\"b289e7f5-28cc-408e-aacd-ab723ef9dfc3\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null},{\"id\":\"94725c3f-d5e8-4301-881f-076517092931\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null},{\"id\":\"7479b758-0b65-4751-a1d2-d5ca2911b596\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null},{\"id\":\"f219f3d5-0ee7-43e8-aaf6-b6622ca377f2\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null}]",
                caseStudy: "",
                difficultyLevel: "beginner",
                questionTypeId: 1,
                explanation: "<p>AWS Config is a service that enables you to assess, audit, and evaluate the configurations of your AWS resources.</p>",
                points: 1,
                tags: "\"[]\"",
                category: {
                    name: "AWS Services",
                    uuid: "fe51d74c-793e-4fd7-a00d-b815227a0c29"
                },
                questionType: {
                    questionTypeName: "single",
                    answerType: "single"
                },
                answers: [
                    {
                        id: 108577,
                        uuid: "f3ef67f0-1653-46e4-a303-7b0a0c9c0a81",
                        answer: "AWS Config",
                        info: "",
                        isCorrect: 1,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    },
                    {
                        id: 108578,
                        uuid: "b90650c9-9319-4a87-8c23-87a555e9df35",
                        answer: "AWS Trusted Advisor ",
                        info: "",
                        isCorrect: 0,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    },
                    {
                        id: 108579,
                        uuid: "95669b38-fa8c-4350-8966-537412ee446b",
                        answer: "Amazon CloudWatch",
                        info: "",
                        isCorrect: 0,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    },
                    {
                        id: 108580,
                        uuid: "e033fbd1-ae16-4752-ba73-64718ecc0756",
                        answer: "Amazon Service Catalog",
                        info: "",
                        isCorrect: 0,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    }
                ]
            }
        },
        {
            questionId: 25789,
            question: {
                id: 25789,
                uuid: "c6be5c6f-9a4f-4e66-8ec6-2857d21f8a88",
                title: "AWT03",
                description: "<p>Distributing workloads across multiple Availability Zones supports which cloud architecture design principle?</p>",
                dropDownQuestions: "[{\"id\":\"92610ad0-2730-4b7a-a04d-2ae75f84a7e8\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null},{\"id\":\"6db6b85f-6353-4793-978b-f3c1f88411e0\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null},{\"id\":\"55d1cf30-df84-4bb4-9c16-b30328a2ae49\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null},{\"id\":\"9dce2ce2-31cc-4743-9da4-502461f03c78\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null}]",
                caseStudy: "",
                difficultyLevel: "beginner",
                questionTypeId: 1,
                explanation: "<p>Using multiple-AZs removes single points of failure, which is part of design for failure (part of &lsquo;Reliability Design Principles and Best Practices&rsquo;)</p>",
                points: 1,
category: {
                    name: "Cloud Computing Concepts and Architectures",
                    uuid: "ba28f3e5-776a-4cdd-8cfa-e0f1481e235d"
                },
                questionType: {
                    questionTypeName: "single",
                    answerType: "single"
                },
                answers: [
                    {
                        id: 101975,
                        uuid: "4cebc4e3-ce84-473c-a1fe-9f025675db4f",
                        answer: "Implement automation",
                        info: "",
                        isCorrect: 0,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    },
                    {
                        id: 101976,
                        uuid: "d579463b-6fb9-41c0-abc4-8d505c457ea4",
                        answer: "Design for agility",
                        info: "",
                        isCorrect: 0,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    },
                    {
                        id: 101977,
                        uuid: "0f7501f7-c94c-48c4-a4f8-24a9df7a8843",
                        answer: "Design for failure",
                        info: "",
                        isCorrect: 1,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    },
                    {
                        id: 101978,
                        uuid: "2e3d94be-d214-4a76-b5f0-4e28d567db1d",
                        answer: "Implement elasticity",
                        info: "",
                        isCorrect: 0,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    }
                ]
            }
        },
        {
            questionId: 25880,
            question: {
                id: 25880,
                uuid: "8d9e04ed-829a-499a-bd93-d337471b9db7",
                title: "AWT39",
                description: "<p>Which of the following services can be used to host a static website?</p>",
                dropDownQuestions: "[{\"id\":\"1135f71c-15e2-44ab-b08d-c30660bce63f\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null},{\"id\":\"32327230-ea91-4d7f-b788-cfefe973d32d\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null},{\"id\":\"85b0e7ac-a336-497c-8259-c6f8c2f1416f\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null},{\"id\":\"be7ed80a-aeab-44b0-89f7-8bb3953b69f3\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null}]",
                caseStudy: "",
                difficultyLevel: "beginner",
                questionTypeId: 1,
                explanation: "<p>Answer:&nbsp;<strong>D</strong></p>\n<p>You can use Amazon S3 as well as Amazon EC2 to host a static website. On a static website, individual web pages include static content.</p>",
                points: 1,
                tags: "\"[]\"",
                category: {
                    name: "AWS Services",
                    uuid: "fe51d74c-793e-4fd7-a00d-b815227a0c29"
                },
                questionType: {
                    questionTypeName: "single",
                    answerType: "single"
                },
                answers: [
                    {
                        id: 103803,
                        uuid: "88da9d2d-489d-44a1-807b-0a4cc485bdd6",
                        answer: "EC2, RDS",
                        info: "",
                        isCorrect: 0,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    },
                    {
                        id: 103804,
                        uuid: "b18e9f4d-5e62-4c7a-9654-098668d866e6",
                        answer: "S3, RDS ",
                        info: "",
                        isCorrect: 0,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    },
                    {
                        id: 103805,
                        uuid: "90d9e0d2-8888-4a2d-b365-75e8b2fde85d",
                        answer: "RDS, Redshift",
                        info: "",
                        isCorrect: 0,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    },
                    {
                        id: 103806,
                        uuid: "52f6168f-2ae3-4fbe-8886-42162723a923",
                        answer: "EC2, S3",
                        info: "",
                        isCorrect: 1,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    }
                ]
            }
        },
        {
            questionId: 25883,
            question: {
                id: 25883,
                uuid: "05083b06-f982-4ac5-be13-1c431fa021d6",
                title: "AWT42",
                description: "<p>You create a new subnet and then add a route to your route table that routes traffic out from that subnet to the Internet using an IGW. What type of subnet have you created?</p>",
                dropDownQuestions: "[{\"id\":\"05daec62-4faf-4524-81a5-81886b64f377\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null},{\"id\":\"7ecc6f5c-075e-4101-b885-049c682b7699\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null},{\"id\":\"73c0038d-65fd-41e9-ad78-72914f07cb3d\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null},{\"id\":\"08312d42-7ea9-4918-be2a-9ff3fe6f72f6\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null}]",
                caseStudy: "",
                difficultyLevel: "beginner",
                questionTypeId: 1,
                explanation: "<p>Answer: <strong>D</strong><br>By creating a route out to the Internet using an IGW, you have made this subnet public.</p>",
                points: 1,
                tags: "\"[]\"",
                category: {
                    name: "AWS Services",
                    uuid: "fe51d74c-793e-4fd7-a00d-b815227a0c29"
                },
                questionType: {
                    questionTypeName: "single",
                    answerType: "single"
                },
                answers: [
                    {
                        id: 103815,
                        uuid: "8a2661f0-16d0-4cfa-a226-ae8cfbacb5bf",
                        answer: "A. An internal subnet",
                        info: "",
                        isCorrect: 0,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    },
                    {
                        id: 103816,
                        uuid: "8834310f-2ba1-4d2b-a5bd-89ebbf836a38",
                        answer: "An external subnet",
                        info: "",
                        isCorrect: 0,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    },
                    {
                        id: 103817,
                        uuid: "359147a6-8c42-4eca-85e9-f85325adaad2",
                        answer: "A private subnet",
                        info: "",
                        isCorrect: 0,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    },
                    {
                        id: 103818,
                        uuid: "dd06f775-09d6-49bf-8f17-84d60bbe831c",
                        answer: " A public subnet",
                        info: "",
                        isCorrect: 1,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    }
                ]
            }
        },
        {
            questionId: 25838,
            question: {
                id: 25838,
                uuid: "3b551b1e-7d20-46ed-839c-da31d3a912ee",
                title: "AWT30",
                description: "<p>Which of the following database engines are compatible with Amazon RDS?</p>",
                dropDownQuestions: "[{\"id\":\"8c3f5623-9978-4a43-8410-43e615453f4e\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null},{\"id\":\"96119a76-3a3c-4930-aa96-93de52b8c2e0\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null},{\"id\":\"ebfd31e8-d176-43be-b844-9c1af7a59e12\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null},{\"id\":\"5fd7af4e-2b80-4b80-bd2d-e4b7bb1695b0\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null}]",
                caseStudy: "",
                difficultyLevel: "beginner",
                questionTypeId: 1,
                explanation: "<p>Answer:&nbsp;<strong>D</strong></p>\n<p>RDS can use Amazon Aurora, PostgreSQL, MySQL, MariaDB, Oracle Database, and Microsoft SQL Server.</p>",
                points: 1,
                tags: "\"[]\"",
                category: {
                    name: "AWS Services",
                    uuid: "fe51d74c-793e-4fd7-a00d-b815227a0c29"
                },
                questionType: {
                    questionTypeName: "single",
                    answerType: "single"
                },
                answers: [
                    {
                        id: 102197,
                        uuid: "1fb50573-2a71-4f0b-a29c-863dcdac0fe0",
                        answer: "A. PostgreSQL, MySQL, MariaDB, Oracle Database, and NoSQL ",
                        info: "",
                        isCorrect: 0,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    },
                    {
                        id: 102198,
                        uuid: "a0d94748-254c-4f66-a257-b14d1512fb86",
                        answer: "NoSQL, MySQL, MariaDB, MongoDB, and Microsoft SQL Server",
                        info: "",
                        isCorrect: 0,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    },
                    {
                        id: 102199,
                        uuid: "3eef901f-f7a0-429d-b4e8-c9cf7ae5f075",
                        answer: "PostgreSQL, MySQL, MariaDB, Amazon Aurora, and Neptune ",
                        info: "",
                        isCorrect: 0,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    },
                    {
                        id: 102200,
                        uuid: "a34103b4-910f-45e5-9d19-9a3a66c79d84",
                        answer: "PostgreSQL, MySQL, MariaDB, Oracle Database, and Microsoft SQL Server",
                        info: "",
                        isCorrect: 1,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    }
                ]
            }
        },
        {
            questionId: 25800,
            question: {
                id: 25800,
                uuid: "c9185745-ac39-4069-9f41-5cfe88277faf",
                title: "AWT14",
                description: "<p>&nbsp;Which of the following statements are true?(Select two)</p>",
                dropDownQuestions: "[{\"id\":\"23498e27-3170-4693-856b-195f8f467836\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null},{\"id\":\"8d1caf68-cd10-4141-b607-96500564503a\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null},{\"id\":\"da69a0bb-49e0-4dfc-aa00-ba1df0566504\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null},{\"id\":\"50baa072-6804-42bd-8062-d112bbb58db5\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null},{\"id\":\"66460cfe-76db-4838-aff9-babfb1c50012\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null}]",
                caseStudy: "",
                difficultyLevel: "beginner",
                questionTypeId: 2,
                explanation: "<p>Answer: After you create the bucket you cannot change its name.</p>\n<p>Every bucket name must be globally unique.</p>",
                points: 1,
category: {
                    name: "AWS Services",
                    uuid: "fe51d74c-793e-4fd7-a00d-b815227a0c29"
                },
                questionType: {
                    questionTypeName: "multiple",
                    answerType: "multiple"
                },
                answers: [
                    {
                        id: 102023,
                        uuid: "cd83041b-d1c3-4481-91e8-90c4a99cef2e",
                        answer: "Multiple Buckets can have the same name.",
                        info: "",
                        isCorrect: 0,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    },
                    {
                        id: 102024,
                        uuid: "d4dfe051-8a15-4282-8abd-4b695ab9d062",
                        answer: "After you create the bucket you can change its name.",
                        info: "",
                        isCorrect: 0,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    },
                    {
                        id: 102025,
                        uuid: "ed5c5da5-53bf-4fd4-9e75-b7b90912e416",
                        answer: "After you create the bucket you cannot change its name.",
                        info: "",
                        isCorrect: 1,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    },
                    {
                        id: 102026,
                        uuid: "4ea11bfa-56ba-469c-94a9-2fe34dd6878c",
                        answer: "By default, you can only create 1 bucket in each of your AWS accounts.",
                        info: "",
                        isCorrect: 0,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    },
                    {
                        id: 102027,
                        uuid: "86aa58d7-3ec4-4a91-a2a1-7790f83014d9",
                        answer: "Every bucket name must be globally unique.",
                        info: "",
                        isCorrect: 1,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    }
                ]
            }
        },
        {
            questionId: 25904,
            question: {
                id: 25904,
                uuid: "0b2bdb85-7099-4b87-85ba-6e3098f368e7",
                title: "AWT58",
                description: "<p>Which AWS Service helps to view operational data from multiple AWS services and automates operational tasks across AWS resources.</p>",
                dropDownQuestions: "[{\"id\":\"e7312f19-cfc3-45ae-b387-d673448abc6c\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null},{\"id\":\"5772e7a9-b96c-4583-bd20-4d9a7be8fd58\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null},{\"id\":\"0434eea4-5ac3-4354-8fff-0a78027bdedb\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null},{\"id\":\"fa9b1807-aab0-4112-96d4-24027a46efc0\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null}]",
                caseStudy: "",
                difficultyLevel: "beginner",
                questionTypeId: 1,
                explanation: "<p>AWS Systems Manager centralizes operational data from multiple AWS services and automates tasks across your AWS resources. It helps to Improve visibility and control in the cloud.</p>",
                points: 1,
                tags: "\"[]\"",
                category: {
                    name: "AWS Services",
                    uuid: "fe51d74c-793e-4fd7-a00d-b815227a0c29"
                },
                questionType: {
                    questionTypeName: "single",
                    answerType: "single"
                },
                answers: [
                    {
                        id: 108585,
                        uuid: "6f6867e0-6565-4a73-a7bd-88fe281b5fbd",
                        answer: "AWS Config",
                        info: "",
                        isCorrect: 0,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    },
                    {
                        id: 108586,
                        uuid: "6c14891b-7978-4e02-a5b6-00a83c641e6a",
                        answer: "AWS OpsWorks ",
                        info: "",
                        isCorrect: 0,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    },
                    {
                        id: 108587,
                        uuid: "5cceb493-7914-40da-b266-8a7ae26808e5",
                        answer: "AWS CloudFormation",
                        info: "",
                        isCorrect: 0,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    },
                    {
                        id: 108588,
                        uuid: "3c3cb941-40c6-4dfa-ad63-3ad8f8d78f57",
                        answer: "AWS Systems Manager",
                        info: "",
                        isCorrect: 1,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    }
                ]
            }
        },
        {
            questionId: 25892,
            question: {
                id: 25892,
                uuid: "6f2ac122-97f8-4d42-8c3e-987c73669dce",
                title: "AWT46",
                description: "<p dir=\"ltr\">Which of the following AWS service can be used to serve large amounts of online video&nbsp;content with the lowest possible latency?</p>",
                dropDownQuestions: "[{\"id\":\"d9d073ef-a17c-4f88-b5e1-2faac519b3a4\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null},{\"id\":\"28be3529-7f8b-4f5f-a922-3bcbd90d6555\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null},{\"id\":\"818eceac-4488-4ebd-84d5-936090b51034\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null},{\"id\":\"b11fc152-583e-4ad6-87a3-70598506ff14\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null}]",
                caseStudy: "",
                difficultyLevel: "beginner",
                questionTypeId: 1,
                explanation: "<p dir=\"ltr\">Amazon CloudFront is a fast content delivery network (CDN) service that securely delivers data, videos, applications, and APIs to customers globally with low latency, high transfer speeds, all within a developer-friendly environment.</p>",
                points: 1,
                tags: "\"[]\"",
                category: {
                    name: "AWS Services",
                    uuid: "fe51d74c-793e-4fd7-a00d-b815227a0c29"
                },
                questionType: {
                    questionTypeName: "single",
                    answerType: "single"
                },
                answers: [
                    {
                        id: 108535,
                        uuid: "fb233702-7267-4b79-85da-a9149459da05",
                        answer: "AWS Storage Gateway",
                        info: "",
                        isCorrect: 0,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    },
                    {
                        id: 108536,
                        uuid: "3e712979-4143-48aa-954c-7be5c8626ae0",
                        answer: " Amazon CloudFront",
                        info: "",
                        isCorrect: 1,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    },
                    {
                        id: 108537,
                        uuid: "f2026e26-890a-406b-9ea7-0fb2d2711b1d",
                        answer: "Amazon Elastic File System (EFS)",
                        info: "",
                        isCorrect: 0,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    },
                    {
                        id: 108538,
                        uuid: "413f5716-66d1-47fe-828e-6659e993899d",
                        answer: "Amazon Glacier",
                        info: "",
                        isCorrect: 0,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    }
                ]
            }
        },
        {
            questionId: 25792,
            question: {
                id: 25792,
                uuid: "1c926620-820a-49d5-be22-759f77b5578d",
                title: "AWT06",
                description: "<p>Which AWS service provides a simple and scalable shared file storage solution for use with multiple Linux-based AWS servers?</p>",
                dropDownQuestions: "[{\"id\":\"4f5e3605-6a9c-45f9-a9e4-4c87dbe62cb8\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null},{\"id\":\"2859bbdd-fb24-42db-82b1-6830740be987\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null},{\"id\":\"5966b5fc-98d1-4dd0-bdd6-a5e55b9d4a62\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null},{\"id\":\"0bae8025-2403-4698-8ebb-9b78d0015026\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null}]",
                caseStudy: "",
                difficultyLevel: "beginner",
                questionTypeId: 1,
                explanation: "<p>Amazon EFS is a Network attached storage which can be used as a shared storage for multiple EC2 Instances.</p>",
                points: 1,
category: {
                    name: "AWS Services",
                    uuid: "fe51d74c-793e-4fd7-a00d-b815227a0c29"
                },
                questionType: {
                    questionTypeName: "single",
                    answerType: "single"
                },
                answers: [
                    {
                        id: 101987,
                        uuid: "f68f45ed-735c-462c-8a4c-1f761aa14055",
                        answer: "Amazon EFS",
                        info: "",
                        isCorrect: 1,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    },
                    {
                        id: 101988,
                        uuid: "0db37a14-00cf-4126-8b2c-7410ba5bcd28",
                        answer: "Amazon S3",
                        info: "",
                        isCorrect: 0,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    },
                    {
                        id: 101989,
                        uuid: "6bcfd45c-5239-4a33-b0bf-9c323b9ac781",
                        answer: "Amazon Glacier",
                        info: "",
                        isCorrect: 0,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    },
                    {
                        id: 101990,
                        uuid: "8eafe7aa-ac66-4b22-9923-f87fa4dff1fb",
                        answer: "Amazon EBS",
                        info: "",
                        isCorrect: 0,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    }
                ]
            }
        },
        {
            questionId: 25872,
            question: {
                id: 25872,
                uuid: "77963027-9b05-4e01-ac50-c8d2f99bee13",
                title: "AWT31",
                description: "<p>Amazon EC2 provides virtual computing environments, known as __________.</p>",
                dropDownQuestions: "[{\"id\":\"9707f816-50c1-4da7-a43e-34203b2cd3de\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null},{\"id\":\"5c0af2b6-7942-4e70-9a7d-0b95934fb79f\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null},{\"id\":\"82c8069c-558c-4db2-8bd8-270bfa42b7e5\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null},{\"id\":\"e3121792-f49e-43a4-8bc7-b1f0ddcce3c1\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null}]",
                caseStudy: "",
                difficultyLevel: "beginner",
                questionTypeId: 1,
                explanation: "<p>Answer:&nbsp;<strong>B</strong></p>\n<p>An instance is a virtual server in the AWS Cloud. With Amazon EC2, you can set up and configure the operating system and applications that run on your instance.</p>",
                points: 1,
                tags: "\"[]\"",
                category: {
                    name: "AWS Services",
                    uuid: "fe51d74c-793e-4fd7-a00d-b815227a0c29"
                },
                questionType: {
                    questionTypeName: "single",
                    answerType: "single"
                },
                answers: [
                    {
                        id: 103771,
                        uuid: "9a984ce4-d991-411c-873d-e92234dc0180",
                        answer: "Instances",
                        info: "",
                        isCorrect: 1,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    },
                    {
                        id: 103772,
                        uuid: "1f110964-3dd4-4abb-a20d-805c39f89809",
                        answer: "Chunks",
                        info: "",
                        isCorrect: 0,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    },
                    {
                        id: 103773,
                        uuid: "750bb3cc-517f-49f9-a212-5c0b3e0b0db6",
                        answer: "None of the mentioned",
                        info: "",
                        isCorrect: 0,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    },
                    {
                        id: 103774,
                        uuid: "767007b6-c452-47cb-a00b-fbcdce5ab0c0",
                        answer: "Messages",
                        info: "",
                        isCorrect: 0,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    }
                ]
            }
        },
        {
            questionId: 25824,
            question: {
                id: 25824,
                uuid: "d6fc37c3-a1a8-4c16-b364-a2c62cd36d14",
                title: "AWT16",
                description: "<p>Which Amazon RDS feature can be used to achieve high availability?</p>",
                dropDownQuestions: "[{\"id\":\"6716bb9c-485e-4b03-b27d-2850f441bb0d\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null},{\"id\":\"51fc90a9-b822-4587-909d-e3bd40ece6ec\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null},{\"id\":\"b3a1c868-de6d-4eb9-93f0-54f878a46ed5\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null},{\"id\":\"38d1b145-81e1-4172-93ef-409e3334e67e\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null}]",
                caseStudy: "",
                difficultyLevel: "beginner",
                questionTypeId: 1,
                explanation: "<p>Amazon RDS provides high availability and failover support for DB instances using Multi-AZ deployments</p>",
                points: 1,
                category: {
                    name: "AWS Services",
                    uuid: "fe51d74c-793e-4fd7-a00d-b815227a0c29"
                },
                questionType: {
                    questionTypeName: "single",
                    answerType: "single"
                },
                answers: [
                    {
                        id: 102137,
                        uuid: "137c5e69-dfb2-4567-be84-825dd26e9ccf",
                        answer: "Multiple Availability Zones",
                        info: "",
                        isCorrect: 1,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    },
                    {
                        id: 102138,
                        uuid: "404b677d-fd63-440e-8faa-e262ed9daaf7",
                        answer: "Amazon Reserved Instances",
                        info: "",
                        isCorrect: 0,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    },
                    {
                        id: 102139,
                        uuid: "91bf748e-62ed-4dcb-b12e-ed5effbe76aa",
                        answer: "Provisioned IOPS storage",
                        info: "",
                        isCorrect: 0,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    },
                    {
                        id: 102140,
                        uuid: "4f30ecd3-e0b0-4845-8ddd-cd6a86fc8a66",
                        answer: "Enhanced monitoring",
                        info: "",
                        isCorrect: 0,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    }
                ]
            }
        },
        {
            questionId: 25897,
            question: {
                id: 25897,
                uuid: "300a5b40-0b0c-46a8-aeac-3be46ff3e8a8",
                title: "AWT51",
                description: "<p>Which of the following services will automatically scale with an expected increase in web traffic?</p>",
                dropDownQuestions: "[{\"id\":\"a189d54d-87d5-4b98-a683-ebc44fb99095\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null},{\"id\":\"829bcbb2-5f4a-46e8-9692-4f526bc8a425\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null},{\"id\":\"bf2ef21d-f335-40ea-902b-51a5df68d281\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null},{\"id\":\"14bcf058-1d2e-4f4f-ba23-ff246058461c\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null}]",
                caseStudy: "",
                difficultyLevel: "beginner",
                questionTypeId: 1,
                explanation: "<p dir=\"ltr\">Elastic Load Balancing automatically distributes incoming application traffic across multiple targets, such as Amazon EC2 instances, containers, IP addresses, Lambda functions, and virtual appliances.</p>\n<p>&nbsp;</p>",
                points: 1,
                tags: "\"[]\"",
                category: {
                    name: "AWS Services",
                    uuid: "fe51d74c-793e-4fd7-a00d-b815227a0c29"
                },
                questionType: {
                    questionTypeName: "single",
                    answerType: "single"
                },
                answers: [
                    {
                        id: 108557,
                        uuid: "8cb26266-6c6c-42c2-bb67-927845ffb7e9",
                        answer: "AWS LightSail",
                        info: "",
                        isCorrect: 0,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    },
                    {
                        id: 108558,
                        uuid: "ff9989f3-1c9f-4774-8145-784f47605364",
                        answer: "Elastic Load Balancing",
                        info: "",
                        isCorrect: 1,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    },
                    {
                        id: 108559,
                        uuid: "ca7d7622-2af8-4d1d-9aa5-b90f0085186f",
                        answer: "Amazon EBS",
                        info: "",
                        isCorrect: 0,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    },
                    {
                        id: 108560,
                        uuid: "d33ff153-e3ec-45fd-a887-204c685ccf5c",
                        answer: "AWS Direct Connect",
                        info: "",
                        isCorrect: 0,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    }
                ]
            }
        },
        {
            questionId: 25876,
            question: {
                id: 25876,
                uuid: "8401d00f-2810-4194-aabd-1a92e55f8a55",
                title: "AWT35",
                description: "<p>Which of the following is a component of Amazon VPC?</p>",
                dropDownQuestions: "[{\"id\":\"d4308609-b630-4533-8c77-256bb3d08b10\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null},{\"id\":\"22a09fa5-ee16-48a2-9ec1-7c452c0feb5e\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null},{\"id\":\"7345cc66-6b62-4ede-bc36-b954e4d92ed4\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null},{\"id\":\"588b77b4-b3a2-44d2-9e46-2f3119b8ee5d\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null}]",
                caseStudy: "",
                difficultyLevel: "beginner",
                questionTypeId: 1,
                explanation: "<p>Answer:&nbsp;<strong>D</strong></p>\n<p>&nbsp;A subnet is a range of IP addresses in your VPC.</p>",
                points: 1,
                tags: "\"[]\"",
                category: {
                    name: "AWS Services",
                    uuid: "fe51d74c-793e-4fd7-a00d-b815227a0c29"
                },
                questionType: {
                    questionTypeName: "single",
                    answerType: "single"
                },
                answers: [
                    {
                        id: 103787,
                        uuid: "5c456992-c918-4905-b00a-b0fbc17db392",
                        answer: "Amazon Machine Image(AMI)",
                        info: "",
                        isCorrect: 0,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    },
                    {
                        id: 103788,
                        uuid: "7d8f8610-cda4-4564-b6a0-04180405ca20",
                        answer: "Object",
                        info: "",
                        isCorrect: 0,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    },
                    {
                        id: 103789,
                        uuid: "4052667a-2276-49f6-81e8-8048a4ae38fa",
                        answer: "Bucket",
                        info: "",
                        isCorrect: 0,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    },
                    {
                        id: 103790,
                        uuid: "cd794250-c173-41c4-972c-510a00bc22c2",
                        answer: "Subnet",
                        info: "",
                        isCorrect: 1,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    }
                ]
            }
        },
        {
            questionId: 25833,
            question: {
                id: 25833,
                uuid: "7adcf7f5-2d5d-47ef-85f7-da63e55937f2",
                title: "AWT25",
                description: "<p>Which service is best for storing common database query results, which helps to alleviate database access load?</p>",
                dropDownQuestions: "[{\"id\":\"8c18f449-3f1c-4198-8824-e45b68c5050e\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null},{\"id\":\"f45464d7-6d43-42e1-978f-21c49c841c8a\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null},{\"id\":\"19346239-d3f2-42b7-a02e-71e31455b444\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null},{\"id\":\"686a2002-3114-4375-b0d5-98cedd09cd54\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null}]",
                caseStudy: "",
                difficultyLevel: "beginner",
                questionTypeId: 1,
                explanation: "<p>ElastiCache can serve frequently requested items at sub-millisecond response times, and enables you to easily scale for higher loads.</p>",
                points: 1,
                category: {
                    name: "AWS Services",
                    uuid: "fe51d74c-793e-4fd7-a00d-b815227a0c29"
                },
                questionType: {
                    questionTypeName: "single",
                    answerType: "single"
                },
                answers: [
                    {
                        id: 102173,
                        uuid: "0c6c29f0-f5eb-4a27-9b6d-f47b53a11534",
                        answer: "Amazon Neptune",
                        info: "",
                        isCorrect: 0,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    },
                    {
                        id: 102174,
                        uuid: "dd1052ef-f375-4d93-b132-0b7d7116291e",
                        answer: "Amazon DMS",
                        info: "",
                        isCorrect: 0,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    },
                    {
                        id: 102175,
                        uuid: "59adac9a-3c92-4fa6-88da-a8cc20ac3f7f",
                        answer: "Amazon ElastiCache",
                        info: "",
                        isCorrect: 1,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    },
                    {
                        id: 102176,
                        uuid: "42e0c3b3-22aa-454b-8d3c-1970f179c959",
                        answer: "Amazon EC2 Instance Store",
                        info: "",
                        isCorrect: 0,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    }
                ]
            }
        },
        {
            questionId: 25827,
            question: {
                id: 25827,
                uuid: "ae783452-92e3-416f-a0fc-d3e28d138a70",
                title: "AWT19",
                description: "<p>Which AWS services can host a Microsoft SQL Server database?</p>",
                dropDownQuestions: "[{\"id\":\"0049db04-40fa-4efa-ad04-f5802d930653\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null},{\"id\":\"55f1bbc3-177e-416c-b7dd-81906ebc5acc\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null},{\"id\":\"8526c9bd-73ee-4cde-8049-a2eceaf73437\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null},{\"id\":\"2ad3db30-d31d-44c3-ac3d-99c5182ef75b\",\"title\":\"\",\"imageId\":null,\"imageUrl\":null}]",
                caseStudy: "",
                difficultyLevel: "beginner",
                questionTypeId: 1,
                explanation: "<p>RDS can use Amazon Aurora, PostgreSQL, MySQL, MariaDB, Oracle Database, and Microsoft SQL Server.</p>",
                points: 1,
                category: {
                    name: "AWS Services",
                    uuid: "fe51d74c-793e-4fd7-a00d-b815227a0c29"
                },
                questionType: {
                    questionTypeName: "single",
                    answerType: "single"
                },
                answers: [
                    {
                        id: 102149,
                        uuid: "5959c2b6-d976-4aaa-b285-38d0b8d0eebf",
                        answer: "Amazon Aurora",
                        info: "",
                        isCorrect: 0,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    },
                    {
                        id: 102150,
                        uuid: "4a0de95a-5729-4f8b-8fdd-71b3f3e9a163",
                        answer: "Amazon S3",
                        info: "",
                        isCorrect: 0,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    },
                    {
                        id: 102151,
                        uuid: "b2a9b056-f1d4-44bb-bcaf-af9a20c733c6",
                        answer: "Amazon Relational Database Service (Amazon RDS)",
                        info: "",
                        isCorrect: 1,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    },
                    {
                        id: 102152,
                        uuid: "e3d6e1ec-21b4-4043-b01a-73332e13d8b4",
                        answer: "Amazon Redshift",
                        info: "",
                        isCorrect: 0,
                        answerOrder: 0,
                        subQuestionId: null,
                        correctOption: null
                    }
                ]
            }
        }
    ],
    quizuserquestions: [
        {
            questionId: 25902,
            isCorrect: false,
            timeTaken: "00:00:00",
            userAnswerId: "108579"
        }
    ],
    quizScore: 0,
    passingScore: 60
}