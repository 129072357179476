import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import Sidebar from "./sidebar";
import {
  ModuleContentBox,
  ModuleContentheaderBox,
  CertificateContentBox,
  AttendanceVideosBox,
} from "./moduleContent.styled";
import ReactPlayer from "react-player";
import cx from "classnames";
import _ from "lodash";
import { GLOBAL_TYPES } from "../../redux/types";
import { ReactComponent as LeftArrow } from "../../assets/icons/left.svg";
import {
  requestGetModuleById,
  requestGetVideosProgress,
  requestSubscribeVideos,
  requestMarkVideoAsCompleted,
} from "../../redux/actions";
import { Button, Modal, Radio } from "../../theme/components";
import EventBus from "eventing-bus";
import Attendance from "./attendaceSystem";

// ** This is Demo data for assessment will be REMOVED soon
const QUESTIONS = [
  {
    id: uuidv4(),
    title:
      "You need to store and retrieve unstructured data such as documents, images, and videos in Azure. Which service should you use?",
    options: [
      "Azure Storage",
      "Azure Cosmos DB",
      "Azure SQL Database",
      "Azure Blob Storage",
    ],
  },
  {
    id: uuidv4(),
    title:
      "You want to deploy a web application on Azure that automatically scales based on demand and does not require managing underlying virtual machines (VMs). Which service should you choose?",
    options: [
      "Azure Virtual Machines",
      "Azure Functions",
      "Azure App Service",
      "Azure Container Instances",
    ],
  },
  {
    id: uuidv4(),
    title:
      "You need to establish a secure and private connection between an on-premises network and an Azure virtual network. What should you use?",
    options: [
      "Azure Traffic Manager",
      "Azure Virtual Network Gateway",
      "Azure Application Gateway",
      "Azure Load Balancer",
    ],
  },
  {
    id: uuidv4(),
    title:
      "You want to deploy and manage a containerized application on Azure that can scale automatically and provide high availability. Which service should you use?",
    options: [
      "Azure Container Registry",
      "Azure Kubernetes Service (AKS)",
      "Azure Functions",
      "Azure Logic Apps",
    ],
  },
];

function ModuleContent(props) {
  const userData = props?.user?.userData?.nasscomUserDetails;
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [played, setPlayed] = useState(false);
  const [isAttendanceEnabled, setIsAttendanceEnabled] = useState(false);
  const [isAttendancePopupEnabled, setIsAttendancePopupEnabled] =
    useState(false);
  const [currentVideo, setCurrentVideo] = useState({});
  const [attendanceVideo, setAtendanceVideo] = useState([]);
  const [sideBarVideosData, setSideBarVideosData] = useState([]);
  const { slug } = useParams();

  const [currentModule, setCurrentModule] = useState({});
  const [videoProgress, setVideoProgress] = useState([]);
  const [showAssessmentModal, setShowAssessmentModal] = useState(false);
  const [isVideoPaused, setIsVideoPaused] = useState(false);

  useEffect(() => {
    //ToGet The selected modules Data
    const requestModulesDataById = EventBus.on(
      GLOBAL_TYPES.GET_MODULES_BY_ID,
      () => {
        setIsLoading(true);
      }
    );
    const getModulesDataByIdSuccess = EventBus.on(
      GLOBAL_TYPES.GET_MODULES_BY_ID_SUCCESS,
      (res) => {
        if (res) {
          setCurrentModule(res.data);
          setSideBarVideosData(res.data.videos);
          setCurrentVideo(res.data.videos[0]);
          getVideoProgressData();
        }
      }
    );
    const getModulesDataByIdFailure = EventBus.on(
      GLOBAL_TYPES.GET_MODULES_BY_ID_FAILED,
      () => {
        setIsLoading(false);
      }
    );

    //videos progress
    const requestgetVideosProgress = EventBus.on(
      GLOBAL_TYPES.GET_VIDEOS_PROGRESS,
      () => {}
    );
    const requestgetVideosProgressSuccess = EventBus.on(
      GLOBAL_TYPES.GET_VIDEOS_PROGRESS_SUCCESS,
      (res) => {
        if (res) {
          setVideoProgress(res?.data);
        }
      }
    );
    const requestgetVideosProgressFailure = EventBus.on(
      GLOBAL_TYPES.GET_VIDEOS_PROGRESS_FAILED,
      () => {}
    );

    //videos progress
    const requestgetVideoMarkAsReads = EventBus.on(
      GLOBAL_TYPES.MARK_VIDEO_AS_COMPLETED,
      () => {}
    );
    const requestgetVideoMarkAsReadsSuccess = EventBus.on(
      GLOBAL_TYPES.MARK_VIDEO_AS_COMPLETED_SUCCESS,
      (res) => {
        if (res) {
          setTimeout(() => {
            // after the video get marked as completed video progress api will fetch the data to mark the video as completed
            getVideoProgressData();
          }, 500);
        }
      }
    );
    const requestgetVideoMarkAsReadsFailure = EventBus.on(
      GLOBAL_TYPES.MARK_VIDEO_AS_COMPLETED_FAILED,
      () => {}
    );
    // user id and module id is mandetory to fetch the data
    if (slug && userData) {
      getModuleDataById(slug);
    }
    return () => {
      requestModulesDataById();
      getModulesDataByIdSuccess();
      getModulesDataByIdFailure();

      requestgetVideosProgress();
      requestgetVideosProgressSuccess();
      requestgetVideosProgressFailure();

      requestgetVideoMarkAsReads();
      requestgetVideoMarkAsReadsSuccess();
      requestgetVideoMarkAsReadsFailure();
    };
  }, [slug, userData]);

  // individualoperation to get the data by the module wise
  const getModuleDataById = (id) => {
    const queryData = {
      uuid: id,
      userId: userData.nasscom_user_id,
    };
    props.requestGetModuleById(queryData);
  };

  // mark video as completed api call after user completes the video 90% and opens assessment modal pausing the video
  const markAsCompleted = () => {
    setPlayed(true);
    setShowAssessmentModal(true);
    setIsVideoPaused(true);
    const reqData = {
      videoId: currentVideo?.id,
      userId: userData.nasscom_user_id,
    };
    props.requestMarkVideoAsCompleted(reqData);
  };

  // get the video progress of a user
  const getVideoProgressData = () => {
    props.requestGetVideosProgress(userData.nasscom_user_id);
  };
  // when user completes the video api will be hitten and get the progress of the video and allowed user to access the next video
  useEffect(() => {
    if (videoProgress && currentModule?.id) {
      const copyVideoProgress = sideBarVideosData?.map((video, videoIndex) => {
        const videoCopy = { ...video };
        videoCopy.isVideoCompleted = false;
        videoProgress?.map((videoStatus) => {
          if (videoStatus?.id === video?.id) {
            videoCopy.isVideoCompleted = true;
          }
        });
        return videoCopy;
      });
      setSideBarVideosData(copyVideoProgress);
      setIsLoading(false);
    }
  }, [videoProgress]); //this runs everytime the video progress updated

  // when the video is playing for the first time this api will be hitten to registed the video for the user
  const subscribeToVideo = () => {
    setPlayed(false);
    console.log("video started");
    const reqData = {
      videoId: currentVideo?.id,
      userId: userData.nasscom_user_id,
      progress: 1,
    };
    props.requestSubscribeVideos(reqData);
  };
  // renders on loading
  if (isLoading) return <div style={{ padding: "50px" }}>Loading ...</div>;
  return (
    <ModuleContentBox>
      <Modal
        show={isAttendancePopupEnabled}
        onClose={() => setIsAttendancePopupEnabled(false)}
      >
        <hi>Select Video For Attendance</hi>
        <AttendanceVideosBox>
          {currentModule?.videos?.map((videoData) => {
            return (
              <div
                onClick={() => setAtendanceVideo(videoData)}
                className={cx({
                  videoselectbox: true,
                  selectedvideo: attendanceVideo.id === videoData.id,
                })}
              >
                {videoData.title}
              </div>
            );
          })}
          <div className="footer">
            <Button
              small
              disabled={!attendanceVideo?.id}
              outline={true}
              style={{ marginLeft: "2rem" }}
              onClick={() => {
                setIsAttendanceEnabled(true);
                setIsAttendancePopupEnabled(false);
              }}
            >
              Continue
            </Button>
          </div>
        </AttendanceVideosBox>
      </Modal>
      <Sidebar
        currentVideo={currentVideo?.id}
        moduleData={sideBarVideosData}
        setCurrentVideo={setCurrentVideo}
        videoTitle={currentVideo?.description}
      />
      {isAttendanceEnabled ? (
        <Attendance
          setIsAttendanceEnabled={setIsAttendanceEnabled}
          data={attendanceVideo}
        />
      ) : (
        <>
          <ModuleContentheaderBox>
            <LeftArrow
              style={{ marginTop: "0.7rem", cursor: "pointer" }}
              onClick={() => {
                navigate(-1);
              }}
            />
            <h3>{currentVideo?.title}</h3>
            <Button
              small
              outline={true}
              style={{ marginLeft: "2rem" }}
              onClick={() => setIsAttendancePopupEnabled(true)}
            >
              Mark Attendance
            </Button>{" "}
          </ModuleContentheaderBox>
          <CertificateContentBox>
            <div
              style={{ height: "100%", width: "100%", borderRadius: "10px" }}
            >
              <ReactPlayer
                // controls={true}
                playing={!isVideoPaused}
                // controls={false}
                url={currentVideo?.videoUrl}
                width="100%"
                height="100%"
                onProgress={(progress) => {
                  if (progress.played > 0.9 && !played) {
                    markAsCompleted();
                  }
                }}
                onStart={() => {
                  subscribeToVideo();
                }}
                onEnded={() => {
                  console.log("video ended");
                  console.log(currentVideo.order);
                  sideBarVideosData.map((video) => {
                    if (currentVideo.order + 1 === video.order) {
                      setCurrentVideo(video);
                    }
                  });
                }}
              />
            </div>
          </CertificateContentBox>
        </>
      )}
      <Modal
        showCloseButton={false}
        show={showAssessmentModal}
        title="Let's test your skills"
        footer={
          <div>
            <Button
              onClick={() => setShowAssessmentModal(false)}
              small
              outline={true}
              style={{ width: "160px" }}
            >
              Submit
            </Button>
          </div>
        }
      >
        {QUESTIONS.map((question, index) => {
          return (
            <div key={question.id}>
              <p>
                {`${index + 1}) `}
                {question.title}
              </p>
              {question.options.map((option, index) => (
                <div key={index + 100}>
                  <Radio label={option} />
                </div>
              ))}
            </div>
          );
        })}
      </Modal>
    </ModuleContentBox>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, {
  requestGetModuleById,
  requestGetVideosProgress,
  requestSubscribeVideos,
  requestMarkVideoAsCompleted,
})(ModuleContent);
