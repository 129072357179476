import { takeEvery, call, put } from "redux-saga/effects";
import {
  requestGetReportListSuccess,
  requestGetReportListFailure,
  requestGetReportDetailSuccess,
  requestGetReportDetailFailure,
} from "../actions";
import { REPORT_TYPES } from "../types";
import { testReportList, detailReport } from "../apis";

export function* watchReportRequests() {
  yield takeEvery(REPORT_TYPES.GET_REPORT_LIST, requestGetReportList);
  yield takeEvery(REPORT_TYPES.GET_REPORT_DETAIL, requestGetReportDetail);
}

function* requestGetReportList(action) {
  try {
    const data = yield call(testReportList, action.payload);
    yield put(requestGetReportListSuccess(data));
  } catch (e) {
    yield put(requestGetReportListFailure(e));
  }
}

function* requestGetReportDetail(action) {
  try {
    const data = yield call(detailReport, action.payload);
    yield put(requestGetReportDetailSuccess(data));
  } catch (e) {
    yield put(requestGetReportDetailFailure(e));
  }
}
