import { toast } from "react-toastify";

//error theme config  
const errorToastConfig = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
}

// this function is used to show error by passing msg which needs to be shown in the toast
export const errorToast = (message = '') => {
    toast.error(message, errorToastConfig);
}