import { API_END_POINTS } from "../endpoints";
import { ApiRequest } from "../../helpers";
import { Convert } from "../../helpers/convert.helper";
import { getDecryptedData } from "../../utility/utils";

export function testReportList(queryData) {
  const query = Convert.ObjectToQuery(queryData);
  return ApiRequest.fetch({
    method: "get",
    url: API_END_POINTS.TEST_REPORT_LIST + `${query && "?" + query}`,
  }).then((response) => response.data);
}

export function detailReport(queryData) {
  const query = Convert.ObjectToQuery(queryData);
  return ApiRequest.fetch({
    method: "get",
    url: API_END_POINTS.DETAIL_REPORT + `${query && "?" + query}`,
  }).then((response) => getDecryptedData(response?.data?.data));
}
