export const GLOBAL_TYPES = {
  GET_MODULES_LIST: "GET_MODULES_LIST",
  GET_MODULES_LIST_SUCCESS: "GET_MODULES_LIST_SUCCESS",
  GET_MODULES_LIST_FAILED: "GET_MODULES_LIST_FAILED",

  GET_MODULES_BY_ID: "GET_MODULES_BY_ID",
  GET_MODULES_BY_ID_SUCCESS: "GET_MODULES_BY_ID_SUCCESS",
  GET_MODULES_BY_ID_FAILED: "GET_MODULES_BY_ID_FAILED",

  GET_VIDEOS_PROGRESS: "GET_VIDEOS_PROGRESS",
  GET_VIDEOS_PROGRESS_SUCCESS: "GET_VIDEOS_PROGRESS_SUCCESS",
  GET_VIDEOS_PROGRESS_FAILED: "GET_VIDEOS_PROGRESS_FAILED",

  REQUEST_SUBSCRIBE_VIDEO: "REQUEST_SUBSCRIBE_VIDEO",
  REQUEST_SUBSCRIBE_VIDEO_SUCCESS: "REQUEST_SUBSCRIBE_VIDEO_SUCCESS",
  REQUEST_SUBSCRIBE_VIDEO_FAILED: "REQUEST_SUBSCRIBE_VIDEO_FAILED",

  MARK_VIDEO_AS_COMPLETED: "MARK_VIDEO_AS_COMPLETED",
  MARK_VIDEO_AS_COMPLETED_SUCCESS: "MARK_VIDEO_AS_COMPLETED_SUCCESS",
  MARK_VIDEO_AS_COMPLETED_FAILED: "MARK_VIDEO_AS_COMPLETED_FAILED",

  GET_LEADERBOARD_USERS: "GET_LEADERBOARD_USERS",
  GET_LEADERBOARD_USERS_SUCCESS: "GET_LEADERBOARD_USERS_SUCCESS",
  GET_LEADERBOARD_USERS_FAILED: "GET_LEADERBOARD_USERS_FAILED",
}