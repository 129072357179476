import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import EventBus from "eventing-bus";
import {
  LeaderBoardBox,
  TableDataBox,
  LeaderBoardUserBox,
  LeaderBoardGraphBox,
  TooltipBox,
  LeaderBoardHeaderBox
} from "./leaderboard.styled";
import defaultImage from '../../assets/images/avatar.png'
import { requestGetLeaderBoardUsers } from "../../redux/actions";
import { GLOBAL_TYPES } from "../../redux/types";
import { Button } from '../../theme/components'
// importign the chart library 
import { BarChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Bar } from 'recharts';
function LeaderBaord(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isShowGraphActive, setIsShowGraphActive] = useState(true);
  const [reportsData, setReportsData] = useState([])

  useEffect(() => {
    //ToGet The leaderboard Data 
    const requestLeaderbaordUsers = EventBus.on(
      GLOBAL_TYPES.GET_LEADERBOARD_USERS,
      () => { setIsLoading(true) }
    );
    const requestLeaderbaordUsersSuccess = EventBus.on(
      GLOBAL_TYPES.GET_LEADERBOARD_USERS_SUCCESS,
      (res) => {
        if (res) {
          setReportsData(res)
          console.log(res)
          setIsLoading(false)
        }
      }
    );
    const requestLeaderbaordUsersFailure = EventBus.on(
      GLOBAL_TYPES.GET_LEADERBOARD_USERS_FAILED,
      () => {
        setIsLoading(false)
      }
    );

    props.requestGetLeaderBoardUsers();

    return () => {
      requestLeaderbaordUsers();
      requestLeaderbaordUsersSuccess();
      requestLeaderbaordUsersFailure();
    }
  }, [])

  // custom tooltip to add additional information on hover graph points
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <TooltipBox >
          <p className="label">Name : {payload[0]['payload'].firstName}  {payload[0]['payload'].lastName}</p>
          <p className="intro">MaxPercentage : {payload[0]['payload'].maxPercentage} </p>
          <p className="desc">Completed : {payload[0]['payload'].completionCount}.</p>
        </TooltipBox>
      );
    }
    return null;
  };

  const toggleGraph = () => {
    setIsShowGraphActive(!isShowGraphActive)
  }

  //on loading return loading 
  if (isLoading) {
    return (<div style={{ padding: '1rem' }}>Loading ...</div>)
  }
  return (
    <LeaderBoardBox>
      <LeaderBoardHeaderBox>
        <h3 className="title">Leaderboard</h3>
        <Button
          onClick={() => toggleGraph()}
          small outline={true}
          style={{ width: '160px' }} >
          {isShowGraphActive ? 'Hide' : 'Show'} Graph
        </Button>
      </LeaderBoardHeaderBox>
      <TableDataBox>
        {
          isShowGraphActive &&
          <LeaderBoardGraphBox>
            <BarChart width={800} height={400} data={reportsData?.data?.length > 1 && [reportsData?.data[0], reportsData?.data[1], reportsData?.data[2],reportsData?.data[3],reportsData?.data[4]]} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
              <Bar dataKey="maxPercentage" fill="#67cc58" />
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="completionCount" />
              <YAxis />
              <Tooltip content={CustomTooltip} cursor={{fill: 'transparent'}} />
            </BarChart>
          </LeaderBoardGraphBox>
        }
        <LeaderBoardUserBox style={{ border: '2px solid #67cc58' }}>
          <p>Position</p>
          <p>Name</p>
          <p>Completed</p>
          <p>Percentage %</p>
        </LeaderBoardUserBox>
        {/* loop through the usersData and return leaderboard user component*/}
        {reportsData?.data?.map((userData, index) => {
          return (
            <LeaderBoardUserBox style={{ marginTop: '1rem' }}>
              <p style={{ fontWeight: 900 }}>{index + 1}</p>
              <p className="image-start">
                <img src={userData.profileImage ? `https://qwikappstorage.blob.core.windows.net/uploads${userData.profileImage}` : defaultImage}
                  style={{ borderRadius: '50%', width: '3rem', height: '3rem' }} />{userData.firstName + " " + userData.lastName}</p>
              <p>{userData.completionCount}</p>
              <p>{userData.maxPercentage}</p>
            </LeaderBoardUserBox>
          )
        })}
      </TableDataBox>
    </LeaderBoardBox>
  );
}
function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, {
  requestGetLeaderBoardUsers
})(LeaderBaord);
