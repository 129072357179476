import { REPORT_TYPES } from "../types";

export function requestGetReportList(payload) {
  return {
    type: REPORT_TYPES.GET_REPORT_LIST,
    payload,
  };
}

export function requestGetReportListSuccess(payload) {
  return {
    type: REPORT_TYPES.GET_REPORT_LIST_SUCCESS,
    payload,
  };
}

export function requestGetReportListFailure(payload) {
  return {
    type: REPORT_TYPES.GET_REPORT_LIST_FAILED,
    payload,
  };
}

export function requestGetReportDetail(payload) {
  return {
    type: REPORT_TYPES.GET_REPORT_DETAIL,
    payload,
  };
}

export function requestGetReportDetailSuccess(payload) {
  return {
    type: REPORT_TYPES.GET_REPORT_DETAIL_SUCCESS,
    payload,
  };
}

export function requestGetReportDetailFailure(payload) {
  return {
    type: REPORT_TYPES.GET_REPORT_DETAIL_FAILED,
    payload,
  };
}
