const BASE_URL = process.env.REACT_APP_API_URL;
const PROD_URL = process.env.REACT_APP_API_PROD_URL;

export const API_END_POINTS = {
  // SIGN_IN: `${BASE_URL}/auth/admin/signIn`,
  SIGN_IN: `${BASE_URL}/auth/signIn-nasscom`,
  GET_MODULES_LIST: `${BASE_URL}/nasscomTraining/module/list?`,
  GET_MODULES_BY_ID: `${BASE_URL}/nasscomTraining/module/info/`,

  GET_VIDEOS_PROGRESS: `${BASE_URL}/nasscomTraining/get-videos-by-user?userId=`,

  SUBSCRIBE_TO_VIDEO: `${BASE_URL}/nasscomTraining/video/progress`,
  MARK_VIDEO_AS_COMPLETED: `${BASE_URL}/nasscomTraining/video/completed?`,

  GET_ATTENDANCE: `${BASE_URL}/nasscomTraining/get-attendance`,
  GET_TRAINING_USERS_LIST: `${BASE_URL}/nasscomTraining/get-user-list`,
  MARK_ATTENDANCE: `${BASE_URL}/nasscomTraining/mark-attendance`,
  
  TEST_REPORT_LIST: `${PROD_URL}/courses/affcor/test-report-list`,
  DETAIL_REPORT: `${PROD_URL}/quiz/affcor/detailReport`,

  LEADERBOARD_USERS: `${BASE_URL}/quiz/leaderboard`,
};