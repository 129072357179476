import styled from "styled-components";
import { COLORS } from "../../../../theme/colors";
import { FONT_SIZES } from "../../../../theme/fontSizes";
import { FONT_WEIGHTS } from "../../../../theme/fontWeights";

export const SidebarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 18px;
  margin-bottom: 20px;
  position: relative;
  .sidebarLeftIcon {
    position: absolute;
    right: 10px;
    top: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 50px;
    cursor: pointer;
    &:hover {
      background: ${COLORS.grey05};
    }
  }
`;
export const TrainingTitle = styled.div`
padding: 0px  25px 40px 25px;
font-weight: 600;
span{
  font-size: 0.7rem;
  font-weight: 300;
}
`
export const SidebarContentBox = styled.div``;

export const SidebarContentUpper = styled.div`
  height: calc(85vh - 100px);
  overflow-y: auto;
  position: relative;
`;

export const SidebarContentBottom = styled.div`
  height: 15.3vh;
  padding: 18px;
  /* overflow-y: auto; */
  position: relative;
`;
export const SidebarContentBottomInnerBox = styled.div``;

export const SidebarComponentBox = styled.div`
  width: 250px;
  background: ${COLORS.white};
  border-right: 2px solid ${COLORS.grey03};
  position: fixed;
  z-index: 1;
  top: 0px;
  left: 0px;
  ${(props) =>
    props.theme.mode === "dark" &&
    ` 
  `};
`;

export const ListDataBox = styled.div`
  font-size: ${FONT_SIZES.BODY_MEDIUM_1};
  color: ${COLORS.grey01};
  display: block;
  text-decoration: unset;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 10px 15px;
  .listDataLeftSide {
    display: flex;
    align-items: center;
  }
  border-radius: 10px;
  overflow: hidden;
  font-weight: ${FONT_WEIGHTS[600]};
  &:hover {
    background: ${COLORS.grey05};
  }
  .listDataLeftSideUpdownArrow {
    animation: slideup 0.5s linear;
    transform: rotate(180deg);
  }
  @keyframes slideup {
    from { 
      transform: rotate(0deg); 
  } to { 
      transform: rotate(180deg); 
  }
  }
`;

export const ListBox = styled.div`
/* width: fit-content; */
margin-bottom: 2rem;
  padding: 0px 30px;
  a {
    color: ${COLORS.blue};
    display: block;
    text-decoration: unset;
  }
  .sidebarListIcon {
    margin-right: 8px;
  }
  .sideBaractive {
    width: 100%;
    font-weight: ${FONT_WEIGHTS[600]};
    border:2px solid #d1f0cb ;
    color: ${COLORS.black};
    border-radius: 10px;
    position: relative;
    &::after{
  content: '';
  height: 100%;
  width: 2px;
  background-color: #68cc58;
  right: -32px;
  top: 0px;
  position: absolute;
}
    .listDataLeftSide {
      color: ${COLORS.black};
    }
    .sidebarListIcon {
      fill: ${COLORS.blue};
      path[stroke] {
        stroke: ${COLORS.white};
      }
    }
  }
`;

export const SidebarProfileContentBox = styled.div`
  background: ${COLORS.grey04};
  border-radius: 10px;
  display: flex;
  padding: 18px;
  cursor: pointer;
  &:hover{
    .hoverProfile{
      display: flex;
    }
  }
  .sidebarProfileImg {
    width: 36px;
    height: 36px;
    background: ${COLORS.grey03};
    border-radius: 50px;
    margin-right: 10px;
  }

  .sidebarProfileTitle {
    font-size: ${FONT_SIZES.BODY_SMALL_2};
    color: ${COLORS.grey01};
  }
  .sidebarProfileUser {
    font-size: ${FONT_SIZES.BODY_EXTRA_SMALL_1};
    color: ${COLORS.grey01};
    &:hover {
      color: ${COLORS.darkBlue};
      cursor: pointer;
      width: fit-content;
    }
  }
`;

export const HoverProfileCard = styled.div`
background: ${COLORS.white};
border-radius: 10px;
position: absolute;
width: 156px;
padding: 6px;
left: 60%;
top:-10px;
box-shadow: -6px 0px 28px rgba(98, 87, 116, 0.03), 26px 32px 24px rgba(98, 87, 116, 0.08);
display: flex;
display:none;
flex-direction: column;
`
export const ProfileOptionCard = styled.div`
font-size: ${FONT_SIZES.BODY_MEDIUM_1};
border-radius: 10px;
padding: 10px;
display: flex;
gap:10px;
&:hover{
  background: ${COLORS.lightBlue2};
  .profileOptionIcon {
      path[stroke] {
        stroke: ${COLORS.blue};
      }
    }
    color: ${COLORS.blue}; ;
}
`