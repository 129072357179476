import { ATTENDANCE_TYPES } from "../types";

//get users attendance
export function requestGetAttendance(payload) {
  return {
    type: ATTENDANCE_TYPES.GET_ATTENDANCE,
    payload,
  };
}

export function requestGetAttendanceSuccess(payload) {
  return {
    type: ATTENDANCE_TYPES.GET_ATTENDANCE_SUCCESS,
    payload,
  };
}

export function requestGetAttendanceFailure(payload) {
  return {
    type: ATTENDANCE_TYPES.GET_ATTENDANCE_FAILED,
    payload,
  };
}

//get attendance Users List
export function requestGetUsersAttendance(payload) {
  return {
    type: ATTENDANCE_TYPES.GET_ATTENDANCE_USERS_LIST,
    payload,
  };
}

export function requestGetUsersAttendanceSuccess(payload) {
  return {
    type: ATTENDANCE_TYPES.GET_ATTENDANCE_USERS_LIST_SUCCESS,
    payload,
  };
}

export function requestGetUsersAttendanceFailure(payload) {
  return {
    type: ATTENDANCE_TYPES.GET_ATTENDANCE_USERS_LIST_FAILED,
    payload,
  };
}

//mark Attendance
export function requestMarkAttendance(payload) {
  return {
    type: ATTENDANCE_TYPES.MARK_ATTENDANCE,
    payload,
  };
}

export function requestMarkAttendanceSuccess(payload) {
  return {
    type: ATTENDANCE_TYPES.MARK_ATTENDANCE_SUCCESS,
    payload,
  };
}

export function requestMarkAttendanceFailure(payload) {
  return {
    type: ATTENDANCE_TYPES.MARK_ATTENDANCE_FAILED,
    payload,
  };
}
