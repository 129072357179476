import * as CryptoJS from "crypto-js";

export const generateKey = () => {
  return CryptoJS.enc.Base64.stringify(CryptoJS.lib.WordArray.random(64));
};

export const getDecryptedData = (encryptedData) => {
  const key = encryptedData.substr(0, 44);
  var bytes = CryptoJS.AES.decrypt(encryptedData.substr(44), key);
  if (bytes.toString(CryptoJS.enc.Utf8)) {
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  }
};

export const encryptData = (data) => {
  const key = generateKey();
  var cipherText = CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
  const encryptedData = key + cipherText;
  return encryptedData;
};

export const generateKey32 = () => {
  return CryptoJS.enc.Base64.stringify(CryptoJS.lib.WordArray.random(32));
};

export const encryptData32 = (data) => {
  const key = generateKey32();
  var cipherText = CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
  const encryptedData = key + cipherText;
  return encryptedData;
};

export const isAnswerCorrect = (option, selectedOption = "") => {
  if (selectedOption && typeof selectedOption == "string") {
    selectedOption = JSON.parse(selectedOption);
  }
  let isCorrect = "";
  if (Array.isArray(option)) {
    if (Array.isArray(selectedOption)) {
      option.forEach((val) => {
        if (selectedOption.includes(val.id)) {
          isCorrect = val.isCorrect;
        }
      });
    } else {
      option.forEach((val) => {
        if (selectedOption == val.id) {
          isCorrect = val.isCorrect;
        }
      });
    }
  } else {
    if (Array.isArray(selectedOption)) {
      if (selectedOption.includes(option.id)) {
        isCorrect = option.isCorrect;
      } else if (option.isCorrect) {
        isCorrect = option.isCorrect;
      }
    } else {
      if (selectedOption == option.id) {
        isCorrect = option.isCorrect;
      } else if (option.isCorrect) {
        isCorrect = option.isCorrect;
      }
    }
  }

  return isCorrect;
};
