export const ATTENDANCE_TYPES = {
  GET_ATTENDANCE: "GET_ATTENDANCE",
  GET_ATTENDANCE_SUCCESS: "GET_ATTENDANCE_SUCCESS",
  GET_ATTENDANCE_FAILED: "GET_ATTENDANCE_FAILED",

  GET_ATTENDANCE_USERS_LIST: "GET_ATTENDANCE_USERS_LIST",
  GET_ATTENDANCE_USERS_LIST_SUCCESS: "GET_ATTENDANCE_USERS_LIST_SUCCESS",
  GET_ATTENDANCE_USERS_LIST_FAILED: "GET_ATTENDANCE_USERS_LIST_FAILED",

  MARK_ATTENDANCE: "MARK_ATTENDANCE",
  MARK_ATTENDANCE_SUCCESS: "MARK_ATTENDANCE_SUCCESS",
  MARK_ATTENDANCE_FAILED: "MARK_ATTENDANCE_FAILED",
};
