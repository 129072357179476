import styled from "styled-components";

export const LeaderBoardBox = styled.div`
  padding: 20px 20px;
  .title{
  font-weight: 600;
}
h3{
    font-size: 1.3rem;
    font-weight: 400;
  }
`;

export const TableDataBox = styled.div`
  padding: 1rem ;
`;

export const LeaderBoardGraphBox = styled.div`
  padding: 1rem ;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const LeaderBoardUserBox = styled.div`
  padding: 5px 10px ;
  border: 2px solid #d1f0cb;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p{
    width: 25%;
    text-align: center;
    img{
      margin-right: 10px;
    }
  }
  .image-start{
    padding-left: 2rem;
    text-align: start;
  }
`;

export const TooltipBox = styled.div`
background-color: white;
border: 1px solid  #d1f0cb;
padding: 1rem;
border-radius: 8px;
`;

export const LeaderBoardHeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
  h3{
    margin-top: 0%.5;
  }
`;
