import styled from "styled-components";

export const CertificateBox = styled.div`
.title{
  font-weight: 600;
}
  padding: 20px 20px;
  p{
    font-size: 1rem;
  }
  h3{
    font-size: 1.3rem;
    font-weight: 400;
  }
  h4{
    font-size: 1.3rem;
    font-weight: 400;
  }
`;

export const CertificateContentBox = styled.div`
width: 100%;
height: 500px;
border: 2px solid gray;
margin-top: 1rem;
border-radius: 10px;
display: flex;
justify-content: space-between;
align-items: center;
flex-direction:column;
`;