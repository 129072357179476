import { API_END_POINTS } from "../endpoints";
import { ApiRequest } from "../../helpers";
import { Convert } from "../../helpers";

export function getModulesList(data) {
  const query = Convert.ObjectToQuery(data)
  return ApiRequest.fetch({
    method: "get",
    url: API_END_POINTS.GET_MODULES_LIST + query,
  }).then((response) => response.data);
}

export function getModulesById(data) {
  const query = Convert.ObjectToQuery(data)
  console.log(query)
  return ApiRequest.fetch({
    method: "get",
    url: API_END_POINTS.GET_MODULES_BY_ID + data.uuid + '?' + query,
  }).then((response) => response.data);
}

export function getVideoProgress(data) {
  return ApiRequest.fetch({
    method: "get",
    url: API_END_POINTS.GET_VIDEOS_PROGRESS + data,
  }).then((response) => response.data);
}

export function subscribeToVideo(params) {
  return ApiRequest.fetch({
    method: "post",
    url: API_END_POINTS.SUBSCRIBE_TO_VIDEO,
    data: params,
  }).then((response) => response.data);
}

export function markVideoAsCompleted(data) {
  const query = Convert.ObjectToQuery(data)
  return ApiRequest.fetch({
    method: "post",
    url: API_END_POINTS.MARK_VIDEO_AS_COMPLETED + query,
  }).then((response) => response.data);
}

export function getLeaderBoardUsers(data) {
  // const query = Convert.ObjectToQuery(data)
  return ApiRequest.fetch({
    method: "get",
    url: API_END_POINTS.LEADERBOARD_USERS,
  }).then((response) => response.data);
}
