export const affcorUserData = {
  totalItems: 7,
  totalPages: 1,
  currentPage: 1,
  data: [
    {
      "User ID": 9777,
      Name: "SAITEJA RAVIKANTI",
      Email: "r.saiteja1445@gmail.com",
      trainingName: "June 2nd AWS Morning Batch",
      "Test Name": "Creating VPC with public and private subnets Lab Test",
      score: "5/5",
      Date: "2023-04-26",
      "Time Taken": "00:00:24",
      percentage: "100",
      Result: "pass",
      quizId: 313,
      userId: 9777,
      practiceTest: "AWS Databases",
    },
    {
      "User ID": 9811,
      Name: "Sahana K",
      Email: "sahanaksss111@gmail.com",
      trainingName: "June 2nd AWS Morning Batch",
      "Test Name": "Creating VPC with public and private subnets Lab Test",
      score: "5/5",
      Date: "2023-04-26",
      "Time Taken": "00:00:30",
      percentage: "100",
      Result: "pass",
      quizId: 313,
      userId: 9811,
      practiceTest: "AWS Databases",
    },
    {
      "User ID": 9742,
      Name: "Tejaswi Mallela",
      Email: "tejaswi.mallela33@gmail.com",
      trainingName: "June 2nd AWS Morning Batch",
      "Test Name": "Creating VPC with public and private subnets Lab Test",
      score: "5/5",
      Date: "2023-04-26",
      "Time Taken": "00:00:22",
      percentage: "100",
      Result: "pass",
      quizId: 313,
      userId: 9742,
      practiceTest: "AWS Databases",
    },
    {
      "User ID": 9807,
      Name: "Uphar Anand",
      Email: "upharanand5@gmail.com",
      trainingName: "June 2nd AWS Morning Batch",
      "Test Name": "Creating VPC with public and private subnets Lab Test",
      score: "5/5",
      Date: "2023-04-27",
      "Time Taken": "00:00:32",
      percentage: "100",
      Result: "pass",
      quizId: 313,
      userId: 9807,
      practiceTest: "AWS Databases",
    },
    {
      "User ID": 9797,
      Name: "Deepak Choudhary",
      Email: "cbdeepakchoudhary@gmail.com",
      trainingName: "June 2nd AWS Morning Batch",
      "Test Name": "Creating VPC with public and private subnets Lab Test",
      score: "4/5",
      Date: "2023-05-03",
      "Time Taken": "00:01:44",
      percentage: "80",
      Result: "pass",
      quizId: 313,
      userId: 9797,
      practiceTest: "AWS Databases",
    },
    {
      "User ID": 9813,
      Name: "Nandish GS",
      Email: "nandishg10@gmail.com",
      trainingName: "June 2nd AWS Morning Batch",
      "Test Name": "Creating VPC with public and private subnets Lab Test",
      score: "5/5",
      Date: "2023-05-07",
      "Time Taken": "00:00:40",
      percentage: "100",
      Result: "pass",
      quizId: 313,
      userId: 9813,
      practiceTest: "AWS Databases",
    },
    {
      "User ID": 9723,
      Name: "Rambabu Gampa",
      Email: "gampa.rambabu9@gmail.com",
      trainingName: "June 2nd AWS Morning Batch",
      "Test Name": "Creating VPC with public and private subnets Lab Test",
      score: "2/5",
      Date: "2023-05-10",
      "Time Taken": "00:01:06",
      percentage: "40",
      Result: "failed",
      quizId: 313,
      userId: 9723,
      practiceTest: "AWS Databases",
    },
    {
      "User ID": 9589,
      Name: "Mahesh Jaladi",
      Email: "connectmrmj@gmail.com",
      trainingName: "June 2nd AWS Morning Batch",
      "Test Name": "Creating VPC with public and private subnets Lab Test",
      score: "3/5",
      Date: "2023-05-10",
      "Time Taken": "00:00:30",
      percentage: "60",
      Result: "failed",
      quizId: 313,
      userId: 9589,
      practiceTest: "AWS Databases",
    },
    {
      "User ID": 9691,
      Name: "Sakshi Garg",
      Email: "sakshig2219@gmail.com",
      trainingName: "June 2nd AWS Morning Batch",
      "Test Name": "Creating VPC with public and private subnets Lab Test",
      score: "2/5",
      Date: "2023-05-10",
      "Time Taken": "00:06:24",
      percentage: "40",
      Result: "failed",
      quizId: 313,
      userId: 9691,
      practiceTest: "AWS Databases",
    },
    {
      "User ID": 9803,
      Name: "Uma B",
      Email: "umab9677@gmail.com",
      trainingName: "June 2nd AWS Morning Batch",
      "Test Name": "Creating VPC with public and private subnets Lab Test",
      score: "5/5",
      Date: "2023-05-20",
      "Time Taken": "00:01:04",
      percentage: "100",
      Result: "pass",
      quizId: 313,
      userId: 9803,
      practiceTest: "AWS Databases",
    },
  ],
};

export const studentData = {
  totalItems: 6,
  totalPages: 1,
  currentPage: 1,
  data: [
    {
      id: 13486,
      uuid: "278bc196-7464-4a48-908b-857f62ebe76e",
      practiceTest: "AWS Databases",
      certification: "",
      training: "HHH AWS Fundamental Training",
      platform: "AWS",
      score: "0/15",
      percentage: "0",
      examMode: "1",
      result: "failed",
      quizId: 223,
      userId: 1,
      time: "00:00:03",
      date: "2023-07-20",
      level: "Beginner",
      createdAt: "2023-07-20T03:59:48.000Z",
      updatedAt: "2023-07-20T03:59:48.000Z",
    },
    {
      id: 13078,
      uuid: "e71b588c-8281-422c-b208-3e6ce6b87491",
      practiceTest: "AWS Basics Test 2",
      certification: "",
      training: "HHH AWS Fundamental Training",
      platform: "AWS",
      score: "3/15",
      percentage: "20",
      examMode: "1",
      result: "failed",
      quizId: 220,
      userId: 1,
      time: "00:00:22",
      date: "2023-07-10",
      level: "Beginner",
      createdAt: "2023-07-10T11:05:13.000Z",
      updatedAt: "2023-07-10T11:05:13.000Z",
    },
    {
      id: 11758,
      uuid: "0d3f3add-4776-42fc-9054-39c6e1040408",
      practiceTest: "Week 5, Assessment 1",
      certification: "",
      training: "HHH AWS Fundamental Training",
      platform: "AWS",
      score: "3/15",
      percentage: "20",
      examMode: "1",
      result: "failed",
      quizId: 225,
      userId: 1,
      time: "00:00:16",
      date: "2023-05-17",
      level: "Beginner",
      createdAt: "2023-05-17T11:52:51.000Z",
      updatedAt: "2023-05-17T11:52:51.000Z",
    },
    {
      id: 9890,
      uuid: "c0eadf40-4fe9-4dfe-bd9a-286ab194e050",
      practiceTest: "Assessment Test - Cloud Computing Basics",
      certification: "",
      training: "",
      platform: "AWS",
      score: "0/15",
      percentage: "0",
      examMode: "1",
      result: "pass",
      quizId: 257,
      userId: 1,
      time: "00:06:33",
      date: "2023-03-25",
      level: "",
      createdAt: "2023-03-25T10:46:27.000Z",
      updatedAt: "2023-03-25T10:46:27.000Z",
    },
    {
      id: 9610,
      uuid: "867fb34d-003a-4072-b0f0-8cba676720ca",
      practiceTest: "AWS EC2",
      certification: "",
      training: "AWS Bootcamp For Beginners",
      platform: "AWS",
      score: "3/15",
      percentage: "20",
      examMode: "0",
      result: "failed",
      quizId: 253,
      userId: 1,
      time: "00:00:00",
      date: "2023-03-08",
      level: "Intermediate",
      createdAt: "2023-03-08T12:36:17.000Z",
      updatedAt: "2023-03-08T12:36:17.000Z",
    },
    {
      id: 9597,
      uuid: "a28f44fe-9e21-4fde-9332-dff9db0af6b2",
      practiceTest: "Class Test 2",
      certification: "",
      training: "AWS Bootcamp For Beginners",
      platform: "AWS",
      score: "5/5",
      percentage: "100",
      examMode: "0",
      result: "pass",
      quizId: 250,
      userId: 1,
      time: "00:00:00",
      date: "2023-03-08",
      level: "Intermediate",
      createdAt: "2023-03-08T10:23:23.000Z",
      updatedAt: "2023-03-08T10:23:23.000Z",
    },
    {
      id: 9553,
      uuid: "6f816da4-cd77-462e-adb3-b00a4bba2e19",
      practiceTest: "Week 6, Assessment 1",
      certification: "",
      training: "HHH AWS Fundamental Training",
      platform: "AWS",
      score: "0/15",
      percentage: "0",
      examMode: "0",
      result: "failed",
      quizId: 227,
      userId: 1,
      time: "00:00:00",
      date: "2023-02-28",
      level: "Beginner",
      createdAt: "2023-02-28T07:37:59.000Z",
      updatedAt: "2023-02-28T07:37:59.000Z",
    },
  ],
};
export const affcorTrainingData = {
  totalItems: 10,
  totalPages: 1,
  currentPage: 1,
  data: [
    {
      "User ID": 9777,
      Name: "SAITEJA RAVIKANTI",
      Email: "r.saiteja1445@gmail.com",
      "Training Name": "June 2nd AWS Morning Batch",
      "Test Name": "Creating VPC with public and private subnets Lab Test",
      Score: "5/5",
      Date: "2023-04-26",
      "Time Taken": "00:00:24",
      Percentage: "100",
      Result: "pass",
      "Quiz ID": 313,
    },
    {
      "User ID": 9811,
      Name: "Sahana K",
      Email: "sahanaksss111@gmail.com",
      "Training Name": "June 2nd AWS Morning Batch",
      "Test Name": "Creating VPC with public and private subnets Lab Test",
      Score: "5/5",
      Date: "2023-04-26",
      "Time Taken": "00:00:30",
      Percentage: "100",
      Result: "pass",
      "Quiz ID": 313,
    },
    {
      "User ID": 9742,
      Name: "Tejaswi Mallela",
      Email: "tejaswi.mallela33@gmail.com",
      "Training Name": "June 2nd AWS Morning Batch",
      "Test Name": "Creating VPC with public and private subnets Lab Test",
      Score: "5/5",
      Date: "2023-04-26",
      "Time Taken": "00:00:22",
      Percentage: "100",
      Result: "pass",
      "Quiz ID": 313,
    },
    {
      "User ID": 9807,
      Name: "Uphar Anand",
      Email: "upharanand5@gmail.com",
      "Training Name": "June 2nd AWS Morning Batch",
      "Test Name": "Creating VPC with public and private subnets Lab Test",
      Score: "5/5",
      Date: "2023-04-27",
      "Time Taken": "00:00:32",
      Percentage: "100",
      Result: "pass",
      "Quiz ID": 313,
    },
    {
      "User ID": 9797,
      Name: "Deepak Choudhary",
      Email: "cbdeepakchoudhary@gmail.com",
      "Training Name": "June 2nd AWS Morning Batch",
      "Test Name": "Creating VPC with public and private subnets Lab Test",
      Score: "4/5",
      Date: "2023-05-03",
      "Time Taken": "00:01:44",
      Percentage: "80",
      Result: "pass",
      "Quiz ID": 313,
    },
    {
      "User ID": 9813,
      Name: "Nandish GS",
      Email: "nandishg10@gmail.com",
      "Training Name": "June 2nd AWS Morning Batch",
      "Test Name": "Creating VPC with public and private subnets Lab Test",
      Score: "5/5",
      Date: "2023-05-07",
      "Time Taken": "00:00:40",
      Percentage: "100",
      Result: "pass",
      "Quiz ID": 313,
    },
    {
      "User ID": 9723,
      Name: "Rambabu Gampa",
      Email: "gampa.rambabu9@gmail.com",
      "Training Name": "June 2nd AWS Morning Batch",
      "Test Name": "Creating VPC with public and private subnets Lab Test",
      Score: "2/5",
      Date: "2023-05-10",
      "Time Taken": "00:01:06",
      Percentage: "40",
      Result: "failed",
      "Quiz ID": 313,
    },
    {
      "User ID": 9589,
      Name: "Mahesh Jaladi",
      Email: "connectmrmj@gmail.com",
      "Training Name": "June 2nd AWS Morning Batch",
      "Test Name": "Creating VPC with public and private subnets Lab Test",
      Score: "3/5",
      Date: "2023-05-10",
      "Time Taken": "00:00:30",
      Percentage: "60",
      Result: "failed",
      "Quiz ID": 313,
    },
    {
      "User ID": 9691,
      Name: "Sakshi Garg",
      Email: "sakshig2219@gmail.com",
      "Training Name": "June 2nd AWS Morning Batch",
      "Test Name": "Creating VPC with public and private subnets Lab Test",
      Score: "2/5",
      Date: "2023-05-10",
      "Time Taken": "00:06:24",
      Percentage: "40",
      Result: "failed",
      "Quiz ID": 313,
    },
    {
      "User ID": 9803,
      Name: "Uma B",
      Email: "umab9677@gmail.com",
      "Training Name": "June 2nd AWS Morning Batch",
      "Test Name": "Creating VPC with public and private subnets Lab Test",
      Score: "5/5",
      Date: "2023-05-20",
      "Time Taken": "00:01:04",
      Percentage: "100",
      Result: "pass",
      "Quiz ID": 313,
    },
  ],
};
