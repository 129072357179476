import { Navigate, Outlet } from "react-router-dom";
import MainLayout from "../views/layouts/main";
import SignIn from "../views/signIn";
import Dashboard from "../views/dashboard";
import NotFound from "../views/notFound";
import Reports from "../views/reports";
import Certificate from "../views/certificate";
import ModuleContent from "../views/moduleContent";
import TrainingModules from "../views/trainingModules";
import LeaderBaord from "../views/leaderboard"
const routes = (isLoggedIn) => [
  {
    path: "/",
    element: isLoggedIn ? (
      <MainLayout>
        <Outlet />
      </MainLayout>
    ) : (
      <Navigate to="/login" />
    ),
    children: [
      { path: "/", element: <Dashboard /> },
      { path: "/dashboard", element: <Dashboard /> },
      { path: "/training-modules", element: <TrainingModules /> },
      { path: "/module/:slug", element: <ModuleContent /> },
      { path: "/certificate", element: <Certificate /> },
      { path: "/reports", element: <Reports /> },
      { path: "/leaderboard", element: <LeaderBaord /> },
    ],
  },
  {
    path: "/",
    element: !isLoggedIn ? <Outlet /> : <Navigate to="/" />,
    children: [
      { path: "login", element: <SignIn /> },
      { path: "/", element: <Navigate to="/login" /> },
    ],
  },
  { path: "/404", element: <NotFound /> },
  { path: "*", element: <NotFound /> },
];

export default routes;
