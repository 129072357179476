export const REPORT_TYPES = {
  GET_REPORT_LIST: "GET_REPORT_LIST",
  GET_REPORT_LIST_SUCCESS: "GET_REPORT_LIST_SUCCESS",
  GET_REPORT_LIST_FAILED: "GET_REPORT_LIST_FAILED",

  GET_REPORT_DETAIL: "GET_REPORT_DETAIL",
  GET_REPORT_DETAIL_SUCCESS: "GET_REPORT_DETAIL_SUCCESS",
  GET_REPORT_DETAIL_FAILED: "GET_REPORT_DETAIL_FAILED",

};
