import styled from "styled-components";

export const ModuleContentBox = styled.div`
  padding: 20px 20px;
  p{
    font-size: 1rem;
  }
  h3{
    font-size: 1.3rem;
    font-weight: 400;
  }
  h4{
    font-size: 1.3rem;
    font-weight: 400;
  }
`;

export const ModuleContentheaderBox = styled.div`
display: flex;
justify-content: flex-start;
align-items:center;
h3{
  font-weight: 900;
}
`;
export const AttendanceVideosBox = styled.div`
margin: 1rem;
display: flex;
justify-content: flex-start;
align-items:center;
gap: 1rem;
flex-wrap: wrap;
h3{
  font-weight: 900;
}
.videoselectbox{
  padding: 5px 10px;
  border: 2px solid #d9d9d9;
  border-radius: 10px;
  cursor: pointer;
  &:hover{
    background-color: #d1f0cb ;
  }
}
.selectedvideo{
  border: 2px solid  #68cc58;
}
.footer{
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
}
`;

export const CertificateContentBox = styled.div`
width: 100%;
height: calc(100vh - 150px);
/* border: 2px solid gray; */
margin-top: 1rem;
border-radius: 10px;
display: flex;
justify-content: space-between;
align-items: center;
flex-direction:column;
`;