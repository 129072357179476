import React, { useEffect, useState, useCallback } from "react";
import EventBus from "eventing-bus";
import { USER_TYPES } from "../../redux/types";
import { connect } from "react-redux";
import { encryptData } from "../../utility/utils";
import {
  updateThemeModeSuccess,
  requestSignInUser,
  requestOtpVerify,
} from "../../redux/actions";
import {
  SignInStyledComponent,
  SignInLeftSideBox,
  SignInRightSideBox,
} from "./signIn.styled";
import { Input, Button } from "../../theme/components";
import { Validate } from "../../helpers";
import LoginPageLeftSideImg from "../../assets/images/loginpageleftsideimg.svg";

const formData = { email: "", password: "" };

function SignIn(props) {
  const [form, setForm] = useState(formData);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormSubmit, setIsFormSubmit] = useState(false);

  useEffect(() => {
    const requestSignIn = EventBus.on(USER_TYPES.SIGN_IN_REQUEST, () =>
      setIsLoading(true)
    );
    const signInSuccess = EventBus.on(USER_TYPES.SIGN_IN_SUCCESS, (res) => {
      setIsLoading(false);
      setIsFormSubmit(false);
    });
    const signInFailure = EventBus.on(USER_TYPES.SIGN_IN_FAILED, () => {
      setIsLoading(false);
      setIsFormSubmit(false);
    });

    return () => {
      requestSignIn();
      signInSuccess();
      signInFailure();
    };
  }, []);

  const handleValidation = () => {
    let errorStructure = {
      errorEmail: "",
      errorPassword: "",
      isValidated: false,
    };

    if (!isFormSubmit) return errorStructure;

    if (!form?.email) {
      errorStructure = { ...errorStructure, errorEmail: "Please enter email" };
    } else if (!Validate.email(form?.email)) {
      errorStructure = {
        ...errorStructure,
        errorEmail: "Please enter valid email",
      };
    }
    if (!form?.password) {
      errorStructure = {
        ...errorStructure,
        errorPassword: "Please enter password",
      };
    } else if (!Validate.minLength(form?.password, 3)) {
      errorStructure = {
        ...errorStructure,
        errorPassword: "Password must be minimum 3 characters",
      };
    }

    if (!errorStructure?.errorEmail && !errorStructure?.errorPassword) {
      errorStructure = {
        ...errorStructure,
        isValidated: true,
      };
    }

    return errorStructure;
  };

  useEffect(() => {
    if (isFormSubmit) {
      handleSubmit();
    }
  }, [isFormSubmit]);

  const onSubmit = () => {
    setIsFormSubmit(Math.random());
  };

  const handleOnEnter = useCallback((event) => {
    if (event.key === "Enter") {
      onSubmit();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleOnEnter, false);

    return () => {
      document.removeEventListener("keydown", handleOnEnter, false);
    };
  }, [handleOnEnter]);

  const handleSubmit = () => {
    const { isValidated } = handleValidation();

    if (!isValidated) return;
    const params = { ...form };
    props.requestSignInUser({ data: encryptData(params) });
  };

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value.trim(),
    });
  };

  const { errorEmail, errorPassword } = handleValidation();
  return (
    <SignInStyledComponent>
      <SignInLeftSideBox>
        <img src={LoginPageLeftSideImg} alt="login" />
      </SignInLeftSideBox>
      <SignInRightSideBox>
        <div>
          <h1>Welcome 👋</h1>
          <br />
          <h5>Please sign-in to your account and start the adventure</h5>
          <br />
          <Input
            label="Email"
            type="email"
            onChange={handleChange}
            name="email"
            value={form.email}
            isError={Boolean(errorEmail)}
            errorMessage={errorEmail}
            disabled={isLoading}
          />
          <br />
          <Input
            label="Password"
            type="password"
            onChange={handleChange}
            value={form.password}
            name="password"
            isError={Boolean(errorPassword)}
            errorMessage={errorPassword}
            disabled={isLoading}
          />
          <br />
          <Button onClick={onSubmit} disabled={isLoading} small outline={true}>
            Sign In{isLoading && "..."}
          </Button>
        </div>
      </SignInRightSideBox>
    </SignInStyledComponent>
  );
}

function mapStateToProps(state) {
  return {
    theme: state?.theme,
    user: state.user,
  };
}

export default connect(mapStateToProps, {
  updateThemeModeSuccess,
  requestSignInUser,
  requestOtpVerify,
})(SignIn);
