import { API_END_POINTS } from "../endpoints";
import { ApiRequest } from "../../helpers";
import { Convert } from "../../helpers/convert.helper";

export function getUsersAttendance(queryData) {
  const query = Convert.ObjectToQuery(queryData);
  // videoId=
  return ApiRequest.fetch({
    method: "get",
    url: API_END_POINTS.GET_ATTENDANCE + `${query && "?" + query}`,
  }).then((response) => response.data);
}

export function getTraininfUserList(queryData) {
  const query = Convert.ObjectToQuery(queryData);
  // trainingId=4
  return ApiRequest.fetch({
    method: "get",
    url: API_END_POINTS.GET_TRAINING_USERS_LIST + `${query && "?" + query}`,
  }).then((response) => response.data);
}

export function markAttendance(queryData) {
  // const query = Convert.ObjectToQuery(queryData);
  return ApiRequest.fetch({
    method: "post",
    url: API_END_POINTS.MARK_ATTENDANCE,
    data: queryData
  }).then((response) => response.data);
}
