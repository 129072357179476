import { takeEvery, call, put } from "redux-saga/effects";
import {
  requestGetAttendanceSuccess,
  requestGetAttendanceFailure,
  requestGetUsersAttendanceFailure,
  requestGetUsersAttendanceSuccess,
  requestMarkAttendanceSuccess,
  requestMarkAttendanceFailure
} from "../actions";
import { ATTENDANCE_TYPES } from "../types";
import { getUsersAttendance, getTraininfUserList, markAttendance } from "../apis";

export function* watchAttendanceRequests() {
  yield takeEvery(ATTENDANCE_TYPES.GET_ATTENDANCE, requestGetUsersAttendance);
  yield takeEvery(ATTENDANCE_TYPES.GET_ATTENDANCE_USERS_LIST, requestGetTraininfUserList);
  yield takeEvery(ATTENDANCE_TYPES.MARK_ATTENDANCE, requestMarkAttendance);
}

function* requestGetUsersAttendance(action) {
  try {
    const data = yield call(getUsersAttendance, action.payload);
    yield put(requestGetAttendanceSuccess(data));
  } catch (e) {
    yield put(requestGetAttendanceFailure(e));
  }
}

function* requestGetTraininfUserList(action) {
  try {
    const data = yield call(getTraininfUserList, action.payload);
    yield put(requestGetUsersAttendanceSuccess(data));
  } catch (e) {
    yield put(requestGetUsersAttendanceFailure(e));
  }
}

function* requestMarkAttendance(action) {
  try {
    const data = yield call(markAttendance, action.payload);
    yield put(requestMarkAttendanceSuccess(data));
  } catch (e) {
    yield put(requestMarkAttendanceFailure(e));
  }
}