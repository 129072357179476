import { GLOBAL_TYPES } from "../types";

//getModules List 
export function requestGetModulesList(payload) {
  return {
    type: GLOBAL_TYPES.GET_MODULES_LIST,
    payload,
  };
}

export function getModulesListSuccess(payload) {
  return {
    type: GLOBAL_TYPES.GET_MODULES_LIST_SUCCESS,
    payload,
  };
}

export function getModulesListFailure(payload) {
  return {
    type: GLOBAL_TYPES.GET_MODULES_LIST_FAILED,
    payload,
  };
}

//getModules List by id
export function requestGetModuleById(payload) {
  return {
    type: GLOBAL_TYPES.GET_MODULES_BY_ID,
    payload,
  };
}

export function requestGetModuleByIdSuccess(payload) {
  return {
    type: GLOBAL_TYPES.GET_MODULES_BY_ID_SUCCESS,
    payload,
  };
}

export function requestGetModuleByIdFailure(payload) {
  return {
    type: GLOBAL_TYPES.GET_MODULES_BY_ID_FAILED,
    payload,
  };
}

//getModules List by id
export function requestGetVideosProgress(payload) {
  return {
    type: GLOBAL_TYPES.GET_VIDEOS_PROGRESS,
    payload,
  };
}

export function requestGetVideosProgressSuccess(payload) {
  return {
    type: GLOBAL_TYPES.GET_VIDEOS_PROGRESS_SUCCESS,
    payload,
  };
}

export function requestGetVideosProgressFailure(payload) {
  return {
    type: GLOBAL_TYPES.GET_VIDEOS_PROGRESS_FAILED,
    payload,
  };
}


//subscribe to video  List by id
export function requestSubscribeVideos(payload) {
  return {
    type: GLOBAL_TYPES.REQUEST_SUBSCRIBE_VIDEO,
    payload,
  };
}

export function requestSubscribeVideosSuccess(payload) {
  return {
    type: GLOBAL_TYPES.REQUEST_SUBSCRIBE_VIDEO_SUCCESS,
    payload,
  };
}

export function requestSubscribeVideosFailure(payload) {
  return {
    type: GLOBAL_TYPES.REQUEST_SUBSCRIBE_VIDEO_FAILED,
    payload,
  };
}

// mark video as completed
export function requestMarkVideoAsCompleted(payload) {
  return {
    type: GLOBAL_TYPES.MARK_VIDEO_AS_COMPLETED,
    payload,
  };
}

export function requestMarkVideoAsCompletedSuccess(payload) {
  return {
    type: GLOBAL_TYPES.MARK_VIDEO_AS_COMPLETED_SUCCESS,
    payload,
  };
}

export function requestMarkVideoAsCompletedFailure(payload) {
  return {
    type: GLOBAL_TYPES.MARK_VIDEO_AS_COMPLETED_FAILED,
    payload,
  };
}

// get leader board users
export function requestGetLeaderBoardUsers(payload) {
  return {
    type: GLOBAL_TYPES.GET_LEADERBOARD_USERS,
    payload,
  };
}

export function requestGetLeaderBoardUsersSuccess(payload) {
  return {
    type: GLOBAL_TYPES.GET_LEADERBOARD_USERS_SUCCESS,
    payload,
  };
}

export function requestGetLeaderBoardUsersFailure(payload) {
  return {
    type: GLOBAL_TYPES.GET_LEADERBOARD_USERS_FAILED,
    payload,
  };
}