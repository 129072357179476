import React from "react";
import { connect } from "react-redux";
import {
  CertificateBox,
  CertificateContentBox
} from "./practiceTest.styled";
import { Button } from "../../theme/components";
function Certificate(props) {
  return (
    <CertificateBox>
      <h3 className="title">Certificate</h3>
      <CertificateContentBox>
        <div style={{backgroundColor:'#e7e7e7' , height:'85%' , width:'100%' , borderRadius:'10px'}}></div>
        <Button small outline={true} style={{marginBottom:'1rem'}}>
          Download Certificate
        </Button>
      </CertificateContentBox>
    </CertificateBox>
  );
}
function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, {
})(Certificate);